import api from '@/core/api'
import { initialMeasurement } from './state'

/***********
 *  Actions
 ***********/
const actions = {
  CREATE: async (context, payload) => {
    let data = initialMeasurement()
    data.fk_user = payload.fk_user
    context.commit('SET_MEASUREMENT', data)
    context.commit('CLEAR_VALIDATE')
  },
  SAVE: async (context, payload) => {
    await api.post('/measurements/save', payload.measurement).then(response => {
      if(response.status == 200) {
        context.commit('SET_MEASUREMENT', response.data.measurement)
        context.commit('SET_MEASUREMENT_SUCCESS', 1)
      }
    })
  },
  LOAD: async (context, { measurement_id }) => {
    let response = await api.get('/measurements/' + measurement_id)
    context.commit('SET_MEASUREMENT', response.data.measurement)
    context.commit('VALIDATE_MEASUREMENT', response.data.measurement)
  },
  LOAD_LIST: async (context) => {
    let response = await api.get('/measurements/')
    context.commit('SET_MEASUREMENTS', response.data)
  },
  LOAD_MEASUREMENTS_OVERVIEW: async (context) => {
    let response = await api.get('/admins/list_measurements/')
    context.commit('SET_MEASUREMENTS_OVERVIEW', response.data)
  },
  LOAD_PATIENT_MEASUREMENT: async (context, payload) => {
    let response = await api.get('/patient/' + payload.id + '/measurements/')
    context.commit('SET_MEASUREMENTS', response.data)
  },
  LOAD_NEW_MEASUREMENTS: async (context) => {
    let response = await api.get('/measurements/notseen/')
    context.commit('SET_NEW_MEASUREMENTS', response.data)
  },
  SET_FK_USER: async (context, payload) => {
    context.commit('SET_FK_USER', payload)
  },
  LIST_ALL_TEMPLATES: async (context) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/measurements/list_all_templates/')
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_TEMPLATES_LIST", response.data)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },

  LOAD_PROFILE: async (context) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/measurements/profile/')
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_PROFILE", response.data)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  LOAD_GOTWORSE_TEXT: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/measurements/gotworsetext/' + payload)
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_GOTWORSE_ITEMS", response.data)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  GET_NEW_COUNT: async (context) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/measurements/countnew')
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_NEW_COUNT", response.data)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  EDIT_FORM_COMPLETED: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.post('/measurements/0/form_completed', {form_completed: payload.form_completed, measurement_id: payload.measurement_id})
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
}



export default actions