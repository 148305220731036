import { initialCategory } from './state'

const mutations = {
  CREATE_CATEGORY (state) {
    state.therapist.categories.push( initialCategory() )
  },

  UPDATE_CATEGORY (state, payload) {
    state.therapist.categories.filter((item) => item._destroy == "0")[payload.index].fk_category = payload.fk_category
  },
  DELETE_CATEGORY (state, payload) {
    state.therapist.categories.filter((item) => item._destroy == "0")[payload.index]._destroy = true
  },
  SET_THERAPIST: (state, payload) => {
    state.therapist.therapist = payload
    state.therapist.categories = payload.categories
  },
  SET_TITLE: (state,payload) => {
    state.therapist.therapist.title = payload
  },
  SET_FIRST_NAME: (state,payload) => {
    state.therapist.therapist.first_name = payload
  },
  SET_LAST_NAME: (state,payload) => {
    state.therapist.therapist.last_name = payload
  },
  SET_EMAIL: (state,payload) => {
    state.therapist.therapist.email = payload
  },
  SET_PHONE: (state,payload) => {
    state.therapist.therapist.phone = payload
  },
  SET_STREET: (state,payload) => {
    state.therapist.therapist.street = payload
  },
  SET_POSTAL_CODE: (state,payload) => {
    state.therapist.therapist.postal_code = payload
  },
  SET_CITY: (state,payload) => {
    state.therapist.therapist.city = payload
  },
  SET_MEMO: (state,payload) => {
    state.therapist.therapist.memo = payload
  },
  SET_REGION: (state,payload) => {
    state.therapist.therapist.region = payload
  },

}

export default mutations