Date.isLeapYear = function(year){
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}

Date.getDaysInMonth = function (year,month){
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function() {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
}

const addMonths = function addMonths(date, months) {
    try{
    var date2 = new Date(date); 
    var d = date2.getDate();
    var m = (date2.getMonth() + months) % 12
    date2.setHours(12); //avoid timezone weirdness, only day month year are returned
    date2.setDate(1);
    date2.setFullYear(date2.getFullYear() + Math.floor((date2.getMonth() + months) / 12));
    date2.setMonth(m);
    date2.setDate(Math.min(date2.getDaysInMonth(m), d));
    return date2.toISOString().split('T')[0];
    }catch{
      return new Date().toISOString().split('T')[0];
    }
}


export {
    addMonths
}