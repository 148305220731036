<template>
  <div class="container p-5">
    <success title="Email wurde gesendet." v-if="SHOW_SUCCESS == 1" />
    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <div class="card text-center">
          <h5 class="card-header">
            Bitte geben Sie Ihre E-Mail Addresse oder ihren Benutzernamen ein.
          </h5>
          <form
            class="needs-validation p-5"
            novalidate="true"
            @submit.prevent="submit"
          >
            <div class="input-group mb-3">
              <input
                v-model="user_field"
                :class="
                  'form-control ' +
                  (USER_FIELD_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Benutzername / E-Mail"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ USER_FIELD_FEEDBACK }}
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Absenden</button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-sm-3"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Success from "@/core/components/Success";

export default {
  name: "PasswordHelper",
  components: {
    Success,
  },
  data() {
    return {
      user_field: "",
    };
  },
  computed: {
    ...mapGetters({
      USER_FIELD_FEEDBACK: "User/USER_FIELD_FEEDBACK",
      SHOW_SUCCESS: "User/SHOW_SUCCESS",
    }),
  },
  methods: {
    submit() {
      this.$store.dispatch("User/NEW_PASSWORD_CHECK_USER", {
        user_field: this.user_field,
      });
    },
  },
};
</script>

<style>
#messages {
  min-height: 5rem;
}
#title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
}
</style>
