<template>
    <div class="bigcard hello">
        <div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="2" @change="change($event)" :checked="notifications_is_2">
  <label class="form-check-label" for="flexRadioDefault1">
    Benachrichtigung über alle Messungen
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="1" @change="change($event)" :checked="notifications_is_1">
  <label class="form-check-label" for="flexRadioDefault2">
    Benachrichtigung nur bei Verschlechterung
  </label>
</div>
    </div>
</template>
<script>

import {computed, onMounted} from 'vue'
import { useStore } from 'vuex'

export default {
  name: "Messages",
  setup() {
    const store = useStore()
    const notifications = computed(() => store.getters['User/NOTIFICATION_SETTINGS'])
    const notifications_is_1 = computed(() => notifications.value == 1)
    const notifications_is_2 = computed(() => notifications.value == 2)

    const change = (event) => {
        store.dispatch("User/SET_NOTIFICATION", {value: event.target.id})
    }

    onMounted(() => {
      store.dispatch("User/LOAD_NOTIFICATION")
    });

    return  {
      change,
      notifications,
      notifications_is_1,
      notifications_is_2
    };
  },
};
</script>