const getters = {
  ERRORS: state => {
    return state.measurement.errors
  },
  ERRORS2: state => {
    return state.measurement.errors2
  },
  MEASUREMENTS: state => {
    return state.measurement.measurements
  },
  MEASUREMENT: state => {
    return state.measurement.measurement
  },
  MEASUREMENT_VALUES: state => {
    return state.measurement.measurement.values
  },
  FK_USER: state => {
    return state.measurement.measurement.fk_user
  },
  SHOW_SUCCESS: state => {
    return state.measurement.show_success
  },
  VALIDATION: state => {
    return state.measurement.validation
  },
  THERAPY_ARM: state => {
    return state.measurement.therapy_arm
  },
  TEMPLATES_LIST: state => {
    return state.measurement.templates
  },
  TEMPLATE: state => {
    return state.measurement.template
  },
  CAN_CREATE_MEASUREMENT: (state) => {
    return state.measurement.canCreateMeasurement
  },
  NEXT_MEASUREMENT: (state) => {
    return state.measurement.nextMeasurement
  },
  USER_ID: (state) => {
    return state.measurement.user_id
  },
  MEASUREMENTS_OVERVIEW: (state) => {
    return state.measurement.measurements_overview
  },
  PROFILE: (state) => {
    return state.measurement.profile
  },
  NEW_COUNT: (state) => {
    return state.measurement.new_count
  },
  NOT_SEEN: (state) => {
    return state.measurement.not_seen
  },
  GOTWORSEITEMS: (state) => {
    return state.measurement.gotworseitems
  }
}

export default getters