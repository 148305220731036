const getters = {
  SCORES: state => {
    return state.category.scores.filter((item) => item._destroy == "0")
  },
  SCORES_ALL: state => {
    return state.category.scores
  },
  CATEGORY: state => {
    return state.category.category
  },
}

export default getters