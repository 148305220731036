import { initialTherapist } from './state'
import api from '@/core/api'

/***********
 *  Actions
 ***********/
 const actions = {
    LOAD_THERAPIST: async (context, payload) => {
        let response = await api.get('/therapist/' + payload.therapist_id)
        context.commit('SET_THERAPIST', response.data.therapist)
      },
      CREATE_THERAPIST: async (context) => {
        let data = initialTherapist()
        context.commit('SET_THERAPIST', data)
      },
}

export default actions