<template>
  <div>
    <success
      title="Messung erfolgreich gespeichert."
      v-if="SHOW_SUCCESS == 1"
    />
    <div class="card hello">
    <h1>Herzlich Willkommen, {{PERSON_STRING.person_string}}!</h1>
    <welcome-patient v-if="user_type == 0" />
    <welcome-doctor v-if="user_type == 1" />
    <welcome-admin v-if="user_type == 2" />
    </div>
    <p v-if="user_type == undefined">Ungültiger Benutzertyp</p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import WelcomeDoctor from "@/modules/measurements/WelcomeDoctor";
import WelcomePatient from "@/modules/measurements/WelcomePatient";
import WelcomeAdmin from "@/modules/measurements/WelcomeAdmin";

import Success from "@/core/components/Success";

export default defineComponent({
  components: {
    WelcomeDoctor,
    WelcomePatient,
    WelcomeAdmin,
    Success,
  },
  beforeRouteLeave() {
    this.$store.commit("Measurements/SET_MEASUREMENT_SUCCESS", 0);
  },
  computed: {
    ...mapGetters({ SHOW_SUCCESS: "Measurements/SHOW_SUCCESS", PERSON_STRING: "User/PERSON_STRING" }),
    user_type() {
      return localStorage.getItem("user_type")
        ? JSON.parse(localStorage.getItem("user_type")).enum
        : undefined;
    },
  },
  mounted(){
    this.$store.dispatch("User/GET_PERSON_STRING")
  }
});
</script>
