import MeasurementMain from '@/modules/measurements/components/Measurement-main'
import Profile from  '@/modules/measurements/components/Profile'
import ProfileWithid from  '@/modules/measurements/components/ProfileWithId'

export default [
    {
        path: '/measurements/:measurementId',
        name: 'measurement',
        meta: { requiresAuth: true },
        component: MeasurementMain
      },
      {
        path: '/profile/last',
        name: 'profile',
        meta: { requiresAuth: true },
        component: Profile
      },
      {
        path: '/profile/:measurementId',
        name: 'withid',
        meta: { requiresAuth: true },
        component: ProfileWithid
      }
  
  ]
  