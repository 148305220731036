import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './core/auth'
import axios from './core/api'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"

import "@/assets/main.css"

const app = createApp(App).use(store).use(router)

app.config.globalProperties.auth = auth
app.config.globalProperties.axios = axios

// Layouts
/*app.component('default-layout', Default)
app.component('no-bars-layout', NoBars)
app.component('no-sidebar-layout', NoSidebar)*/

import DefaultLayout from '@/layouts/DefaultLayout'
app.component('default-layout', DefaultLayout)

import NoBars from '@/layouts/NoBars'
app.component('no-bars-layout', NoBars)

app.mount('#app')
