const mutations = {

  SET_PASSWORD: (state, payload) => {
    state.user.password = payload
  },
  SET_ERRORS: (state, payload) => {
    state.user.errors = payload
  },
  SET_ERRORS2: (state, payload) => {
    state.user.errors2 = payload
  },
  SET_CHANGE_PASSWORD_FEEDBACK: (state, payload) => {
    let property_errors = payload.user ? payload.user.error : []
    state.user.old_password_feedback = property_errors.old_password ? property_errors.old_password : ""
    state.user.password1_feedback = property_errors.password1 ? property_errors.password1 : ""
    state.user.password2_feedback = property_errors.password2 ? property_errors.password2 : ""
  },
  SET_PASSWORD_RECOVERY_FEEDBACK: (state, payload) => {
    let property_errors = payload.user ? payload.user.error : []
    state.user.password1_feedback = property_errors.password1 ? property_errors.password1 : ""
    state.user.password2_feedback = property_errors.password2 ? property_errors.password2 : ""
  },
  SET_CONTACT_FEEDBACK: (state, payload) => { 
    let property_errors = payload.error ? payload.error : []
    state.user.name_feedback = property_errors.name ? property_errors.name : ""
    state.user.title_feedback = property_errors.title ? property_errors.title : ""
    state.user.message_feedback = property_errors.message ? property_errors.message : ""
    state.user.email_feedback = property_errors.email ? property_errors.email : ""
    state.user.captcha_feedback = property_errors.captcha ? property_errors.captcha : ""
  },
  SET_CHANGE_PASSWORD_SUCCESS: (state, payload) => {
    state.user.show_success = payload
  },
  SET_OLD_PASSWORD_FEEDBACK: (state, payload) => {
    state.user.old_password_feedback = payload
  },
  SET_PASSWORD1_FEEDBACK: (state, payload) => {
    state.user.password1_feedback = payload
  },
  SET_PASSWORD2_FEEDBACK: (state, payload) => {
    state.user.password2_feedback = payload
  },
  SET_PDF_DATA: (state, payload) => {
    state.user.pdf_data = payload
  },
  SET_PATIENTS_LIST: (state, payload) => {
    state.user.patients_list = payload
  },
  SET_MEASUREMENTS_LIST_MODAL_VISIBLE: (state, payload) => {
    state.user.welcome_page_selected_patient = payload.patientId
    state.user.measurements_list_modal_visible = payload.visible
  },
  SET_NEW_MEASUREMENTS_MODAL_VISIBLE: (state, payload) => {
    state.user.new_measurement_modal_visible = payload.visible
  },
  SET_WELCOME_PAGE_SELECTED_PATIENT: (state, payload) => {
    state.user.welcome_page_selected_patient = payload
  },
  SET_USERNAME: (state, payload) => {
    state.user.username = payload
  },
  SET_TOKEN_VALID: (state, payload) => {
    state.user.token_valid = payload
  },
  SET_SUCCESS: (state, payload) => {
    state.user.show_success = payload
  },
  SET_QUIZ: (state, payload) => {
    state.user.quiz = payload
  },
  SET_CONTACT_SUCCESS: (state, payload) => {
    state.user.contact_success = payload
  },
  SET_THERAPISTS_LIST: (state, payload) => {
    state.user.therapists = payload
    state.user.categories = [... new Set(payload.therapists.map(t => {
      return t.category_name}))]
    state.user.regions  = [... new Set(payload.therapists.map(t => {
      return t.region_name}))]
  },
  SET_NOTIFICATION: (state, payload) => {
    state.user.notification_settings = payload.value
  },
  SET_PERSON_STRING: (state, payload) => {
    state.user.person_string = payload.value
  }
}

export default mutations