<template>
  <div class="container p-5">
    <error-list
      :errors="ERRORS"
    />
    <error-list
      :errors="ERRORS2"
      variant="warning"
      title="Hinweis"
    />
    <success-new-user
      title="Benutzer anlegen war erfolgreich"
      v-if="SUCCESS == 1"
      :email="this.email"
    />

    <div class="row">
      <div class="col-sm-3">
      </div>
      <div class="col-sm-6">
        <div class="card text-center">
          <h5 class="card-header">LQ-Monitoring von Patienten mit Lungenkarzinom</h5>
          <div class="card-body">
        </div>
        <form class="needs-validation p-5"
          novalidate="true"
          @submit.prevent="register"
        >
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="title"
            :class="'form-control ' + (TITLE_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Titel"
            required
            autofocus
          />
          <div class="invalid-feedback">
            {{ TITLE_FEEDBACK }}
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="username"
            :class="'form-control ' + (USER_NAME_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Benutzername"
            required
            autofocus
          />
          <div class="invalid-feedback">
            {{ USER_NAME_FEEDBACK }}
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="study_number"
            :class="'form-control ' + (STUDY_NUMBER_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Studiennummer"
            required
            autofocus
          />
          <div class="invalid-feedback">
            {{ STUDY_NUMBER_FEEDBACK }}
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            v-model="first_name"
            :class="'form-control ' + (FIRST_NAME_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Vorname"
            required
          />
          <div class="invalid-feedback">
            {{ FIRST_NAME_FEEDBACK }}
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            v-model="last_name"
            :class="'form-control ' + (LAST_NAME_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Nachname"
            required
          />
          <div class="invalid-feedback">
            {{ LAST_NAME_FEEDBACK }}
          </div>
        </div>
        <div class="input-group mb-3 mt-3">
             <label>Geschlecht</label>
            <select :value="SEX" class="mb-6" @change="updateSex($event.target.value)">
              <option v-for="sex in [{id: 0, value: 'k.a'}, {id: 1, value: 'männlich'},{id: 2, value: 'weiblich'}]" :key="sex.id" :value="sex.id">  
                {{sex.value}}
              </option>
            </select>
             </div>
        <div class="input-group mb-3">
          <input
            v-model="email"
            :class="'form-control ' + (EMAIL_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Email"
            required
          />
          <div class="invalid-feedback">
            {{ EMAIL_FEEDBACK }}
          </div>
             <div class="input-group mb-3 mt-3">
             <label>Studienregion</label>
            <select :value="REGION" class="mb-6" @change="updateRegion($event.target.value)">
              <option v-for="region in REGIONS.regions" :key="region.id" :value="region.id">  
                {{region.name}}
              </option>
            </select>
             </div>
        </div>
        <div class="row mt-3">
          <h5>Benutzerrolle</h5>
        </div>
        <div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="user_type" id="0" v-model="user_type" value=0>
            <label class="form-check-label" for="0">
              Patient
            </label>
          </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="user_type" id="1" v-model="user_type" value=1>
          <label class="form-check-label" for="1">
            Arzt/Klinik
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="user_type" id="2" v-model="user_type" value=2>
          <label class="form-check-label" for="2">
            Vertrauensstelle
          </label>
        </div>
      </div>
      <div v-if="user_type_is_0">
      <div class="row mt-3">
        <h5>Therapieabschnitt</h5>
      </div>
      <div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="therapyArm" id="0" v-model="therapy_arm" value=0>
          <label class="form-check-label" for="0">
            k.A.
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="therapyArm" id="1" v-model="therapy_arm" value=1>
          <label class="form-check-label" for="1">
            Interventionsabschnitt
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="therapyArm" id="2" v-model="therapy_arm" value=2>
          <label class="form-check-label" for="2">
            Kontrollabschnitt
          </label>
        </div>
      </div>
       <button
          type="button"
          @click="showListPatientClinicsModal"
          class="btn btn-primary col-12"
        >
          Klinik auswählen
        </button>
          <div style="color: #ff0000" v-if="clinicChanged">
            ausgewählte Klinik: {{ clinicChanged }}
          </div>
          <hr>
                        <h3>Messzeitpunkte</h3>
                        <div class="row">
                        <label for="measurement0">Messung 0 (Rekrutierungszeitpunkt)</label>
                        <input  @blur="measurement0Blur()"   
                        :class="'form-control ' + (MEASUREMENT0_FEEDBACK != '' || measurement0_feedback != '' ? 'is-invalid' : '')"
                        type="date" id="measurement0" name="measurement0"  v-model="measurement0">
                          <div class="invalid-feedback">
                            {{ measurement0_feedback }}
                            <br v-if="MEASUREMENT0_FEEDBACK != '' && measurement0_feedback != ''">
                            {{ MEASUREMENT0_FEEDBACK }}
                          </div>
                        </div>
                        <div class="row">
                        <label for="measurement1">Messung 1</label>
                        <div class="input-group">
                        <input  @blur="measurement1Blur()"   
                        :class="'form-control ' + (MEASUREMENT1_FEEDBACK != '' || measurement1_feedback != '' ? 'is-invalid' : '')"
                        type="date" id="measurement1" name="measurement1"  v-model="measurement1">
                        <button
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                        data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m1afterm0(1)">
                        </button>
                          <div class="invalid-feedback">
                            {{ measurement1_feedback }}
                            <br v-if="MEASUREMENT1_FEEDBACK != '' && measurement1_feedback != ''">
                            {{ MEASUREMENT1_FEEDBACK }}
                          </div>
                          </div>
                        </div>
                        <div class="row">
                        <label for="measurement2">Messung 2</label>
                        <div class="input-group">
                        <input @blur="measurement2Blur()" 
                        :class="'form-control ' + (MEASUREMENT2_FEEDBACK != '' || measurement2_feedback != '' ? 'is-invalid' : '')" 
                        type="date" id="measurement2" name="measurement2" v-model="measurement2">
                                <button
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                        data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m2afterm1(1)">
                        </button>
                                     <div class="invalid-feedback">
                            {{ measurement2_feedback }}
                            <br v-if="MEASUREMENT2_FEEDBACK != '' && measurement2_feedback != ''">
                            {{ MEASUREMENT2_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <label for="measurement3">Messung 3</label>
                                   <div class="input-group">
                        <input @blur="measurement3Blur()" 
                        :class="'form-control ' + (MEASUREMENT3_FEEDBACK != '' || measurement3_feedback != '' ? 'is-invalid' : '')"
                        type="date" id="measurement3" name="measurement3" v-model="measurement3">
                        <button 
                        type="button"
                        class="btn btn-primary bi-calendar-plus input-group-append"
                        data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m3afterm2(1)">
                        </button>
                                           <div class="invalid-feedback">
                            {{ measurement3_feedback }}
                            <br v-if="MEASUREMENT3_FEEDBACK != '' && measurement3_feedback != ''">
                            {{ MEASUREMENT3_FEEDBACK }}
                          </div>
                                   </div>
                        </div>
                        <div class="row">
                        <label for="measurement4">Messung 4</label>
                        <div class="input-group">
                        <input @blur="measurement4Blur()" 
                        :class="'form-control ' + (MEASUREMENT4_FEEDBACK != '' || measurement4_feedback != ''  ? 'is-invalid' : '')"
                        type="date" id="measurement4" name="measurement4"  v-model="measurement4">
                        <button 
                        type="button"
                        class="btn btn-primary bi-calendar-plus input-group-append"
                        data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m4afterm3(1)">
                        </button>
                                     <div class="invalid-feedback">
                            {{ measurement4_feedback }}
                            <br v-if="MEASUREMENT4_FEEDBACK != '' && measurement4_feedback != ''">
                            {{ MEASUREMENT4_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <label for="measurement5">Messung 5</label>
                        <div class="input-group">
                        <input @blur="measurement5Blur()" 
                        :class="'form-control ' + (MEASUREMENT5_FEEDBACK != '' || measurement5_feedback != ''  ? 'is-invalid' : '')" 
                        type="date" id="measurement5" name="measurement5"  v-model="measurement5">
                        <button 
                        type="button"
                        class="btn btn-primary bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m5afterm4(1)">
                        </button>
                                       <div class="invalid-feedback">
                            {{ measurement5_feedback }}
                            <br v-if="MEASUREMENT5_FEEDBACK != '' && measurement5_feedback != ''">
                            {{ MEASUREMENT5_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <label for="measurement6">Messung 6</label>
                        <div class="input-group">
                        <input @blur="measurement6Blur()" 
                        :class="'form-control ' + (MEASUREMENT6_FEEDBACK != '' || measurement6_feedback != ''  ? 'is-invalid' : '')" 
                         type="date" id="measurement6" name="measurement6"  v-model="measurement6">
                        <button 
                        type="button"
                        class="btn btn-primary bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m6afterm5(1)">
                        </button>
                                     <div class="invalid-feedback">
                                      {{ measurement6_feedback }}
                                      <br v-if="MEASUREMENT6_FEEDBACK != '' && measurement6_feedback != ''">
                            {{ MEASUREMENT6_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        </div>
      <div class="row mt-3">
        <button
          type="submit"
          class="btn btn-primary col-12"
        >
          Neuen Benutzer Registrieren
        </button>
      </div>
    </form>
  </div>
      </div>
    </div>
    <div class="col-sm-3">
    </div>
      <list-clinics-for-patient-modal
        ref="listClinicsForPatientModal"
        :show="patientListClinicsModalVisible"
        :patient="patient"
        @modalClosed="listPatientClinicsModalClosed"
        @fkClinicChanged="fkClinicChanged"
        />
  </div>
</template>

<script>
import { browserInfo } from '@/core/mixins/vue_browser_detect'
import ErrorList from '@/core/components/ErrorList'
import SuccessNewUser from '@/core/components/SuccessNewUser'
import { mapGetters } from 'vuex'
import ListClinicsForPatientModal from '@/modules/admins/components/ListClinicsForPatientModal'
import * as dt from '@/core/mixins/date.js'

export default {
  name: 'Register',
  components: {
    ErrorList,
    SuccessNewUser,
    ListClinicsForPatientModal
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      username: '',
      study_number: '',
      title: '',
      password1: '',
      password2: '',
      first_name: '',
      last_name: '',
      email: '',
      therapy_arm: 0,
      user_type:0,
      patientListClinicsModalVisible: false,
      search_key: '',
      per_page: 15,
      patient: {id: 0},
                  minDate: new Date().toISOString().split('T')[0],
                  measurement0: this.measurement0 ? this.measurement0 : new Date().toISOString().split('T')[0],
            measurement1: this.measurement1 ? this.measurement1 : dt.addMonths(new Date().toISOString().split('T')[0],1),
            measurement2: this.measurement2 ? this.measurement2 : dt.addMonths(new Date().toISOString().split('T')[0],2),
            measurement3: this.measurement3 ? this.measurement3 : dt.addMonths(new Date().toISOString().split('T')[0],3),
            measurement4: this.measurement4 ? this.measurement4 : dt.addMonths(new Date().toISOString().split('T')[0],4),
            measurement5: this.measurement5 ? this.measurement5 : dt.addMonths(new Date().toISOString().split('T')[0],5),
            measurement6: this.measurement6 ? this.measurement6 : dt.addMonths(new Date().toISOString().split('T')[0],6),
            measurement0_feedback: "",
            measurement1_feedback: "",
            measurement2_feedback: "",
            measurement3_feedback: "",
            measurement4_feedback: "",
            measurement5_feedback: "",
            measurement6_feedback: "",
      region: 0,
      clinicChanged: false
    }
  },
  created () {
    /***********************************************************************
     * hier wird geprüft, ob vom router eine error_message übergeben wurde.
     * siehe axios-instance.js.
     * hier werden alle 401-Fehler aufgefangen, die von Rails im
     * ApplicationController in der require_login! - Action gerendert werden.
     ***********************************************************************/

    let bi = browserInfo()
    if ( bi.isIE ) {
      this.errors2 = ["Bei der Verwendung des Internet-Explorers sind manche Funktionen eingeschränkt. Wir empfehlen die Benutzung von Chrome, Firefox oder Edge."]
    }
  },
  computed: {
    ...mapGetters({ ERRORS: 'Admin/ERRORS',
                    ERRORS2: 'Admin/ERRORS2', 
                    SUCCESS: 'Admin/NEW_USER_SUCCESS', 
                    FIRST_NAME_FEEDBACK: 'Admin/FIRST_NAME_FEEDBACK', 
                    LAST_NAME_FEEDBACK: 'Admin/LAST_NAME_FEEDBACK',
                    USER_NAME_FEEDBACK: 'Admin/USER_NAME_FEEDBACK',
                    STUDY_NUMBER_FEEDBACK: 'Admin/STUDY_NUMBER_FEEDBACK',
                    TITLE_FEEDBACK: 'Admin/TITLE_FEEDBACK',
                    MEASUREMENT0_FEEDBACK: 'Admin/MEASUREMENT0_FEEDBACK',
                    MEASUREMENT1_FEEDBACK: 'Admin/MEASUREMENT1_FEEDBACK',
                    MEASUREMENT2_FEEDBACK: 'Admin/MEASUREMENT2_FEEDBACK',
                    MEASUREMENT3_FEEDBACK: 'Admin/MEASUREMENT3_FEEDBACK',
                    MEASUREMENT4_FEEDBACK: 'Admin/MEASUREMENT4_FEEDBACK',
                    MEASUREMENT5_FEEDBACK: 'Admin/MEASUREMENT5_FEEDBACK',
                    MEASUREMENT6_FEEDBACK: 'Admin/MEASUREMENT6_FEEDBACK',
                    EMAIL_FEEDBACK: 'Admin/EMAIL_FEEDBACK',
                    FK_CLINIC: 'Admin/FK_CLINIC',
                    REGISTER_KEY: 'Admin/REGISTER_KEY',
                    REGIONS: 'Admin/REGIONS',
                    REGION: 'Admin/REGION',
                    SEX: 'Admin/SEX'}),
    browserInfo () {
      return browserInfo
    },
    user_type_is_0() {
      return this.user_type == 0
    },
    
    errors: {
      get(){
        return this.ERRORS
      },
      set(value){
        this.$store.commit("Admin/SET_ERRORS", value)
      }
    },

    errors2: {
      get(){
        return this.ERRORS2
      },
      set(value){
        this.$store.commit("Admin/SET_ERRORS2", value)
      }

    }
  },
  methods: {
    register () {
      this.$store.dispatch("Admin/REGISTER", {study_number: this.study_number, title: this.title, user_name: this.username, first_name: this.first_name, last_name: this.last_name, sex: this.SEX, email: this.email == "" ? null : this.email, therapy_arm: parseInt(this.therapy_arm), user_type: parseInt(this.user_type), fk_clinic: parseInt(this.FK_CLINIC), measurement0: this.measurement0, measurement1: this.measurement1, measurement2: this.measurement2, measurement3: this.measurement3, measurement4: this.measurement4, measurement5: this.measurement5, measurement6: this.measurement6, region: this.REGION})
    },
    listPatientClinicsModalClosed ()  {
            this.patientListClinicsModalVisible = false
    },
    showListPatientClinicsModal () {
            this.patientListClinicsModalVisible = true
    },
    fkClinicChanged(value) {
        this.$store.commit("Admin/SET_FK_CLINIC", value.clinic == null ? null : value.clinic.id)
        this.clinicChanged = value.clinic == null ? null : value.clinic.user_name
    },
    m1afterm0(value){
      this.measurement1 = dt.addMonths(this.measurement0, value)
    },
    m2afterm1(value){
      this.measurement2 = dt.addMonths(this.measurement1, value)
    },
    m3afterm2(value){
      this.measurement3 = dt.addMonths(this.measurement2, value)
    },
    m4afterm3(value){
      this.measurement4 = dt.addMonths(this.measurement3, value)
    },
    m5afterm4(value){
      this.measurement5 = dt.addMonths(this.measurement4, value)
    },
    m6afterm5(value){
      this.measurement6 = dt.addMonths(this.measurement5, value)
    },
    measurement0Blur(){
      var d = new Date(this.measurement0)
      if(d instanceof Date && !isNaN(d)){
        this.measurement0_feedback = ""
      }else{
        this.measurement0_feedback = "Ungültiges Datum"
      }
    },
    measurement1Blur(){
      var d = new Date(this.measurement1)
      if(d instanceof Date && !isNaN(d)){
        this.measurement1_feedback = ""
      }else{
        this.measurement1_feedback = "Ungültiges Datum"
      }
    },
    measurement2Blur(){
      var d = new Date(this.measurement2)
      if(d instanceof Date && !isNaN(d)){
        this.measurement2_feedback = ""
      }else{
        this.measurement2_feedback = "Ungültiges Datum"
      }
    },
    measurement3Blur(){
      var d = new Date(this.measurement3)
      if(d instanceof Date && !isNaN(d)){
        this.measurement3_feedback = ""
      }else{
        this.measurement3_feedback = "Ungültiges Datum"
      }
    },
        measurement4Blur(){
      var d = new Date(this.measurement4)
      if(d instanceof Date && !isNaN(d)){
        this.measurement4_feedback = ""
      }else{
        this.measurement4_feedback = "Ungültiges Datum"
      }
    },
        measurement5Blur(){
      var d = new Date(this.measurement5)
      if(d instanceof Date && !isNaN(d)){
        this.measurement5_feedback = ""
      }else{
        this.measurement5_feedback = "Ungültiges Datum"
      }
    },
        measurement6Blur(){
      var d = new Date(this.measurement6)
      if(d instanceof Date && !isNaN(d)){
        this.measurement6_feedback = ""
      }else{
        this.measurement6_feedback = "Ungültiges Datum"
      }
    },
        updateRegion(region){
          this.$store.commit("Admin/SET_REGION", region)
        },
        updateSex(sex){
          this.$store.commit("Admin/SET_SEX", sex)
        }
  },
  watch: {
    "REGISTER_KEY": function() {
      this.publicPath = process.env.BASE_URL
      this.username = ''
      this.study_number = ''
      this.title = ''
      this.password1 = ''
      this.password2 = ''
      this.first_name = ''
      this.last_name = ''
      this.email = ''
      this.therapy_arm = 0
      this.user_type = 0
      this.patientListClinicsModalVisible = false
      this.search_key = ''
      this.per_page = 15
      this.patient = {id: 0}
      this.$store.commit("Admin/SET_NEW_USER_SUCCESS", false)
            this.measurement0 = this.measurement0 ? this.measurement0 : new Date().toISOString().split('T')[0]
            this.measurement1 = this.measurement1 ? this.measurement1 : dt.addMonths(new Date().toISOString().split('T')[0],1)
            this.measurement2 = this.measurement2 ? this.measurement2 : dt.addMonths(new Date().toISOString().split('T')[0],2)
            this.measurement3 = this.measurement3 ? this.measurement3 : dt.addMonths(new Date().toISOString().split('T')[0],3)
            this.measurement4 = this.measurement4 ? this.measurement4 : dt.addMonths(new Date().toISOString().split('T')[0],4)
            this.measurement5 = this.measurement5 ? this.measurement5 : dt.addMonths(new Date().toISOString().split('T')[0],5)
            this.measurement6 = this.measurement6 ? this.measurement6 : dt.addMonths(new Date().toISOString().split('T')[0],6)
    }
  },
  mounted() {
      this.$store.commit("Admin/SET_NEW_USER_SUCCESS", false)
      this.$store.dispatch("Admin/LIST_REGIONS")
  }
}
</script>

<style>
  #messages {
    min-height: 5rem;
  }
  #title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;
  }
</style>
