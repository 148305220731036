import { initialCategory } from './state'
import api from '@/core/api'

/***********
 *  Actions
 ***********/
 const actions = {
    LOAD_CATEGORY: async (context, payload) => {
        let response = await api.get('therapists/categories/' + payload.category_id)
        context.commit('SET_CATEGORY', response.data.category)
      },
      CREATE_CATEGORY: async (context) => {
        let data = initialCategory()
        context.commit('SET_CATEGORY', data)
      },
}

export default actions