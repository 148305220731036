<template>
  <div class="alert alert-danger" role="alert"
    :variant="variant"
    v-if="errors.length > 0"
    dismissible
  >
    <h4
      class="alert-heading"
    >
      {{ title }}
    </h4>
    <hr>
    <ul>
      <li
        v-for="error in errors"
        :key="error"
      >
        {{ error }}              
      </li>
      <div v-if="loginFailed"><a href="./users/password_helper">Haben Sie ihr Passwort vergessen?</a></div>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ErrorList',
  props: {
    errors: { type: Array, default: function () { return [] } },
    title: { type: String, default: 'Es sind Fehler aufgetreten' },
    variant: { type: String, default: 'danger' },
  },
  computed: {
    loginFailed(){
        return this.errors.includes("Sie konnten nicht angemeldet werden.") || this.errors.includes("Sie haben Ihr Passwort dreimal falsch eingegeben. Bitte wenden Sie sich an Ihren Administrator.")
    }
  }
}
</script>