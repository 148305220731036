<template>
  <div class="container p-5">
    <error-list :errors="ERRORS" />
    <error-list :errors="ERRORS2" variant="warning" title="Hinweis" />
    <success title="Passwort erfolgreich geändert." v-if="SHOW_SUCCESS == 1" />

    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <div class="card text-center">
          <h5 class="card-header">Passwort ändern
              <p style="color: orange">Passwort-Richtlinien: mind. 8 Zeichen, 1 Kleinbuchstabe, 1 Großbuchstabe, 1 Zahl, ein Sonderzeichen (z.B. !$?)</p>
          </h5>
           <form
            class="needs-validation p-5"
            novalidate="true"
            @submit.prevent="submit"
          >
            <div class="input-group mb-3">
              <input
                v-model="old_password"
                :class="
                  'form-control ' +
                  (OLD_PASSWORD_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Altes Passwort"
                type="password"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ OLD_PASSWORD_FEEDBACK }}
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-model="password1"
                :class="
                  'form-control ' +
                  (PASSWORD1_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Neues Passwort"
                type="password"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ PASSWORD1_FEEDBACK }}
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-model="password2"
                :class="
                  'form-control ' +
                  (PASSWORD2_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Passwort Wiederholen"
                type="password"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ PASSWORD2_FEEDBACK }}
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Absenden</button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-sm-3"></div>
  </div>
</template>

<script>
import { browserInfo } from "@/core/mixins/vue_browser_detect";
import ErrorList from "@/core/components/ErrorList";
import Success from "@/core/components/Success";
import { ref, onMounted } from "vue";
import { mapGetters, useStore } from "vuex";

export default {
  name: "ChangePassword",
  components: {
    ErrorList,
    Success,
  },
  setup() {
    const store = useStore();
    const publicPath = ref(process.env.BASE_URL);
    const property_errors = ref([]);
    const username = ref("");
    const old_password = ref("");
    const password1 = ref("");
    const password2 = ref("");
    const success = ref(0);

    onMounted(() => {
      clearFeedback();
    });

    const handleInput = (event) => {
      if (event.getModifierState("CapsLock")) {
        store.commit(
          "User/SET_OLD_PASSWORD_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
        store.commit(
          "User/SET_PASSWORD1_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
        store.commit(
          "User/SET_PASSWORD2_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
      } else {
        clearFeedback();
      }
    };
    const submit = () => {
      store.dispatch("User/CHANGE_PASSWORD", {
        old_password: old_password.value,
        password1: password1.value,
        password2: password2.value,
      });
    };
    const clearFeedback = () => {
      store.commit("User/SET_OLD_PASSWORD_FEEDBACK", "");
      store.commit("User/SET_PASSWORD1_FEEDBACK", "");
      store.commit("User/SET_PASSWORD2_FEEDBACK", "");
    };

    return {
      publicPath,
      property_errors,
      username,
      old_password,
      password1,
      password2,
      success,
      handleInput,
      submit,
    };
  },
  created() {
    /***********************************************************************
     * hier wird geprüft, ob vom router eine error_message übergeben wurde.
     * siehe axios-instance.js.
     * hier werden alle 401-Fehler aufgefangen, die von Rails im
     * ApplicationController in der require_login! - Action gerendert werden.
     ***********************************************************************/

    let bi = browserInfo();
    if (bi.isIE) {
      this.errors2 = [
        "Bei der Verwendung des Internet-Explorers sind manche Funktionen eingeschränkt. Wir empfehlen die Benutzung von Chrome, Firefox oder Edge.",
      ];
    }
  },
  computed: {
    ...mapGetters({
      ERRORS: "User/ERRORS",
      ERRORS2: "User/ERRORS2",
      SHOW_SUCCESS: "User/SHOW_SUCCESS",
      OLD_PASSWORD_FEEDBACK: "User/OLD_PASSWORD_FEEDBACK",
      PASSWORD1_FEEDBACK: "User/PASSWORD1_FEEDBACK",
      PASSWORD2_FEEDBACK: "User/PASSWORD2_FEEDBACK",
    }),
    browserInfo() {
      return browserInfo;
    },

    errors: {
      get() {
        return this.ERRORS;
      },
      set(value) {
        this.$store.commit("USER/SET_ERRORS", value);
      },
    },

    errors2: {
      get() {
        return this.ERRORS2;
      },
      set(value) {
        this.$store.commit("USER/SET_ERRORS2", value);
      },
    },
  },
};
</script>

<style>
#messages {
  min-height: 5rem;
}
#title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
}
</style>
