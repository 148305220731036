const initialState = function () {
  return {
    errors: [],
    errors2: [],
    old_password_feedback: '',
    password1_feedback: '',
    password2_feedback: '',
    user_field_feedback: '',
    user_type: '',
    therapy_arm: '',
    pdf_data: null,
    patients_list: [],
    measurements_list_modal_visible: false,
    new_measurement_modal_visible: false,
    welcome_page_selected_patient: false,
    username: '',
    token_valid: false,
    show_success: false,
    quiz: null,
    name_feedback: '',
    title_feedback: '',
    message_feedback: '',
    captcha_feedback: '',
    email_feedback: '',
    contact_success: false,
    therapists: [],
    categories: [],
    regions: [],
    notification_settings: 0,
    person_string: ''
  }
}

const state = {
  user: initialState(),
}

export { state, initialState, }