<template>
  <div>
    <div class="card col bigcard">
      <div class="card-body">
        <div class="row">
          <div class="input-group">
            <label>Titel</label>
            <input type="text" placeholder="Titel" aria-label="Titel" v-model="title"/>
          </div>
        </div>
        <div class="row">
          <div class="input-group">
            <label>Vorname</label>
            <input type="text" placeholder="Vorname" aria-label="Vorname" v-model="first_name"/>
          </div>
        </div>
        <div class="row mb-3">
          <div class="input-group">
            <label>Nachname</label>
            <input type="text" placeholder="Nachname" aria-label="Nachname" v-model="last_name"/>
          </div>
        </div>
        <div class="row">
          <div class="input-group">
            <label>Email</label>
            <input type="text" placeholder="Email" aria-label="Email" v-model="email"/>
          </div>
        </div>
        <div class="row">
          <div class="input-group mb-3">
            <label>Telefon</label>
            <input type="text" placeholder="Telefon" aria-label="Telefon" v-model="phone"/>
          </div>
        </div>
        <div class="row">
          <div class="input-group">
            <label>Strasse</label>
            <input type="text" placeholder="Strasse" aria-label="Strasse" v-model="street"/>
          </div>
        </div>
        <div class="row">
          <div class="input-group">
            <label>PLZ</label>
            <input type="text" placeholder="PLZ" aria-label="PLZ" v-model="postal_code"/>
          </div>
        </div>
        <div class="row">
          <div class="input-group mb-3">
            <label>Stadt</label>
            <input type="text" placeholder="Stadt" aria-label="Stadt" v-model="city"/>
          </div>
        </div>
        <div class="row">
          <div class="input-group mb-3">
            <label>Memo</label>
            <input type="text" placeholder="memo" aria-label="memo" v-model="memo"/>
          </div>
        </div>
        <div class="row md-6">
             <div class="input-group mb-3">
             <label>Studienregion</label>
            <select :value="selected" class="mb-6" @change="updateRegion($event.target.value)">
              <option v-for="region in REGIONS.regions" :key="region.id" :value="region.id">  
                {{region.name}}
              </option>
            </select>
             </div>
          </div>
        <div class="row">
          <table class="mb-3">
            <div v-for="(row, index) in CATEGORIES" :key="row.id">
              <div class="input-group dropdown">
              <label>Kategorie</label>
              <select class="form-select" :value="row.fk_category" @change="updateCategory(index, $event.target.value)">
              <option
                v-for="c in CATEGORIES_LIST.categories"
                :key="c.id"
                :value="c.id">
                {{c.name}}
              </option>
              </select>
              <button class="btn btn-primary" @click="deleteCategory(index)">
                -
              </button>
            </div>
            </div>
          </table>
        </div>
        <div class="row mb-3">
            <button class="btn btn-primary button-lg" @click="addCategory()">
              +
            </button> 
        </div>
        <div class="row mb-3">
          <button v-if="isNewRoute" type="button" class="btn btn-primary button-lg" @click="addTherapist">Neuer Therapeut</button>
          <button v-if="!isNewRoute" type="button" class="btn btn-primary button-lg" @click="editTherapist">Speichern</button>
        </div>
        <div class="row">
          <button v-if="!isNewRoute" type="button" class="btn btn-danger button-lg" @click="deleteTherapist(therapist.id)">Löschen</button>
        </div>
      </div>
    </div>  
    <delete-therapist-modal
        ref="deleteTherapistModal"
            :show="deleteTherapistModalVisible"
            :therapist_id="therapistId"
            @modalClosed="deleteTherapistModalClosed"
            @deletedTherapist="afterDeleteTherapist"
            v-if="deleteTherapistModalVisible">

        </delete-therapist-modal>
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, watch, ref } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import DeleteTherapistModal from '@/modules/admins/components/DeleteTherapistModal'
export default defineComponent({
    setup() {
           const store = useStore()
           const route = useRoute()
           const router = useRouter()
          const therapistId = parseInt(route.params.therapistId)
               const therapist = computed(() => store.getters["Therapist/THERAPIST"])
               const categories = computed(() => store.getters["Therapist/CATEGORIES_ALL"])
          const selected = computed(() => store.getters["Therapist/REGION"])
          const deleteTherapistModalVisible = ref(false);

          const showDeleteTherapistModal = () => {
            deleteTherapistModalVisible.value = true
        }

        const deleteTherapistModalClosed = () => {
            deleteTherapistModalVisible.value = false
        }

        const deleteTherapist= () => {
            showDeleteTherapistModal()
        }

        const afterDeleteTherapist = () => {
          deleteTherapistModalVisible.value = false
          router.push("/admins/therapists")
        }

          const title = computed(
              {
                get:()=>{
                  return therapist.value.title
                },
                set:(value)=>{
                  store.commit("Therapist/SET_TITLE", value)
                }
              }
            )
            const first_name = computed(
              {
                get:()=>{
                  return therapist.value.first_name
                },
                set:(value)=>{
                  store.commit("Therapist/SET_FIRST_NAME", value)
                }
              }
            )
            const last_name = computed(
              {
                get:()=>{
                  return therapist.value.last_name
                },
                set:(value)=>{
                  store.commit("Therapist/SET_LAST_NAME", value)
                }
              }
            )
             const email = computed(
              {
                get:()=>{
                  return therapist.value.email
                },
                set:(value) =>{
                  store.commit("Therapist/SET_EMAIL", value)
                }
              }
            )
              const phone = computed(
              {
                get:()=>{
                  return therapist.value.phone
                },
                set:(value)=>{
                  store.commit("Therapist/SET_PHONE", value)
                }
              }
            )
               const street = computed(
              {
                get:() => {
                  return therapist.value.street
                },
                set: (value) => {
                  store.commit("Therapist/SET_STREET", value)
                }
              }
            )
                const postal_code = computed(
              {
                get: () => {
                  return therapist.value.postal_code
                },
                set: (value) => {
                  store.commit("Therapist/SET_POSTAL_CODE", value)
                }
              }
            )
                 const city = computed(
              {
                get: () => {
                  return therapist.value.city
                },
                set: (value) => {
                  store.commit("Therapist/SET_CITY", value)
                }
              }
            )
                  const memo = computed(
              {
                get: () => {
                  return therapist.value.memo
                },
                set: (value) => {
                  store.commit("Therapist/SET_MEMO", value)
                }
              }
            )


            const addTherapist = () => {
                  store.dispatch("Admin/ADD_THERAPIST", {categories: categories.value,title: title.value, first_name: first_name.value, last_name: last_name.value, email: email.value,
                  phone: phone.value, street: street.value, postal_code: postal_code.value, city: city.value, memo: memo.value, region: selected.value})
                  router.push("/admins/therapists")
        }

             const editTherapist = () => {
                  store.dispatch("Admin/EDIT_THERAPIST", {categories: categories.value,title: title.value, first_name: first_name.value, last_name: last_name.value, email: email.value,
                  phone: phone.value, street: street.value, postal_code: postal_code.value, city: city.value, memo: memo.value, id: route.params.therapistId, region: selected.value })
               router.push("/admins/therapists")
        }

        const addCategory = () => {
          store.commit("Therapist/CREATE_CATEGORY")
        }

        const updateCategory = (index, fk_category) => {
          store.commit("Therapist/UPDATE_CATEGORY", {index: index, fk_category: fk_category})
        }

        const deleteCategory = (index) => {
          store.commit('Therapist/DELETE_CATEGORY', { index: index } )
        }

        const isNewRoute = computed(() => {return route.params.therapistId == "new"} )

        const updateRegion = (region) => {
          store.commit("Therapist/SET_REGION", region)
        }

        watch(() => route.params.therapistId, async newId => {
            if(newId == "new"){
                store.dispatch('Therapist/CREATE_THERAPIST')
                return
            }else{
                store.dispatch('Therapist/LOAD_THERAPIST', { therapist_id: newId })
            }
        })

        onMounted(()=> {
            store.dispatch("Admin/LIST_ALL_CATEGORIES")
            store.dispatch("Admin/LIST_REGIONS")
            if(therapistId == "new"){
                store.dispatch('Therapist/CREATE_THERAPIST')
                return
            }else{
                store.dispatch('Therapist/LOAD_THERAPIST', { therapist_id: route.params.therapistId })
            }
        }
        )


        return {
         title,first_name,last_name, email, phone, street, postal_code, city, memo, addTherapist, categories,therapistId,therapist,isNewRoute,editTherapist,addCategory, updateCategory, deleteCategory, selected, updateRegion, showDeleteTherapistModal, deleteTherapistModalVisible,  deleteTherapistModalClosed, deleteTherapist, afterDeleteTherapist
        }
    },
    computed: {
          ...mapGetters({CATEGORIES: "Therapist/CATEGORIES",CATEGORIES_LIST: "Admin/CATEGORIES", THERAPIST: "Therapist/THERAPIST", REGIONS: "Admin/REGIONS"}),
          
    },components: {
        DeleteTherapistModal
    },
})
</script>
<style scoped>
label{
  margin-right: 10px;
  width: 100px;
  text-align: right;
}
.dropdown{
  width: 400px;
}
input{
  width: 400px;
}
.button-lg{
  width: 400px;
}
</style>