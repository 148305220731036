<template>
  <div class="DefaultLayout">
    <div id="layout_background">
      <img src="@/assets/bg.jpg" class="stretch" alt="" />
    </div>
    <navbar />
    <slot/>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Navbar from '@/layouts/Navbar.vue'

export default defineComponent({
    components: {
        Navbar
    }
})
</script>
<style>
  #layout_background{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: -1;
  }
  .stretch {
    width: 100%;
    height: 100%;
  }
</style>