<template>
  <div class="container p-5">
    <error-list :errors="ERRORS" />
    <error-list :errors="ERRORS2" variant="warning" title="Hinweis" />

    <div class="row align-items-center">
      <div class="col-sm-10 col-md-10 col-xs-8 mx-auto">
        <div class="card text-center">
          <h5 class="card-header">
            LePaLuMo<br />LQ-Monitoring von Patienten mit Lungenkarzinom
          </h5>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 col-md-12 col-xs-12 pt-2">
                <img
                  alt="TUZBT"
                  src="@/assets/logo-university-of-bayreuth.png"
                  class="img-fluid p-2"
                  style="width: 170px"
                />
              </div>
              <div class="col-lg-4 col-md-12 col-xs-12">
                <img
                  alt="TUZR"
                  src="@/assets/logo_tuz.png"
                  class="img-fluid p-2"
                  style="width: 170px"
                />
              </div>
              <!--div class="col-lg-3 col-md-12 col-xs-12">
                <img
                  alt="ZKS"
                  src="@/assets/logo_zks.png"
                  class="img-fluid p-2"
                  style="width: 140px"
                />
              </div-->
              <div class="col-lg-4 col-md-12 col-xs-12">
                <img
                  alt="LGL"
                  src="@/assets/logo_lepalumo.png"
                  class="img-fluid p-2"
                  style="width: 130px"
                />
              </div>
            </div>
          </div>
          <form
            class="needs-validation px-5 pb-5"
            novalidate="true"
            @submit.prevent="login"
          >
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                v-model="username"
                placeholder="Benutzername"
                required
                autofocus
                @keydown="handleInput"
              />
            </div>
            <div class="input-group mb-3">
              <input
                v-model="password"
                :class="
                  'form-control ' +
                  (password_feedback != '' ? 'is-invalid' : '')
                "
                placeholder="Passwort"
                type="password"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ password_feedback }}
              </div>
            </div>
            <button type="submit" class="btn btn-primary col-6">Login</button>
                 <button type="button" class="btn btn-primary col-6" @click="forgot_password()">Passwort vergessen</button>
                 <button type="button" class="btn btn-primary col-6 mt-5" @click="contact">Kontakt</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { browserInfo } from "@/core/mixins/vue_browser_detect";
import ErrorList from "@/core/components/ErrorList";
import { mapGetters, useStore } from "vuex";
import { ref } from "vue";
import { useRouter} from 'vue-router';

export default {
  name: "Login",
  components: {
    ErrorList,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const publicPath = ref(process.env.BASE_URL);
    const username = ref("");
    const password = ref("");
    const password_feedback = ref("");

    const handleInput = (event) => {
      if (event.getModifierState("CapsLock")) {
        password_feedback.value = "Warnung: die SHIFT-LOCK-Taste ist gedrückt.";
      } else {
        password_feedback.value = "";
      }
    };

    const contact = () => {
      router.push("/contact")
    }

    const login = () => {
      store.dispatch("User/LOGIN", {
        user_name: username.value,
        password: password.value,
      });
      password.value = "";
    };

    const forgot_password = () => {
      router.push("users/password_helper")
    }

    return {
      publicPath,
      username,
      password,
      password_feedback,
      handleInput,
      login,
      contact,
      forgot_password
    };
  },
  created() {
    /***********************************************************************
     * hier wird geprüft, ob vom router eine error_message übergeben wurde.
     * siehe axios-instance.js.
     * hier werden alle 401-Fehler aufgefangen, die von Rails im
     * ApplicationController in der require_login! - Action gerendert werden.
     ***********************************************************************/

    let bi = browserInfo();
    if (bi.isIE) {
      this.errors2 = [
        "Bei der Verwendung des Internet-Explorers sind manche Funktionen eingeschränkt. Wir empfehlen die Benutzung von Chrome, Firefox oder Edge.",
      ];
    }
  },
  computed: {
    ...mapGetters({ ERRORS: "User/ERRORS", ERRORS2: "User/ERRORS2" }),
    browserInfo() {
      return browserInfo;
    },

    errors: {
      get() {
        return this.ERRORS;
      },
      set(value) {
        this.$store.commit("USER/SET_ERRORS", value);
      },
    },

    errors2: {
      get() {
        return this.ERRORS2;
      },
      set(value) {
        this.$store.commit("USER/SET_ERRORS2", value);
      },
    },
  },
};
</script>

<style>
#messages {
  min-height: 5rem;
}
#title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
}
</style>
