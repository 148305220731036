<template>
  <div class="card col bigcard">
    <div class="card-body">
      <img :src="imageSrc" v-if="imageSrc"/>
    </div>
 </div>
</template>
<script>
import { defineComponent} from "vue";
import { mapGetters } from "vuex";
import axios from "@/core/api";

export default defineComponent({
  data(){
    return {
      imageSrc: null
    }
  },
  computed: {
    ...mapGetters({
      PROFILE: "Measurements/PROFILE",
    }),
  },
  methods: {
    async loadImage() {
      //var fileName = this.USER_ID + ".png";
      var url = "/measurements/profile";
      const response = await axios.get(url, { responseType: "blob" });
      if (response.status == 200) {
        const base64data = await await this.blobToData(response.data);
        this.imageSrc = base64data;
      }
    },
    async blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }
  },
  mounted() {
    this.$store.dispatch("Measurements/LOAD_PROFILE");
    this.imageSrc = this.loadImage();
  },
});
</script>

