<template>
    <div>
        <button type="button" class="btn btn-primary" @click="this.downloadWithAxios()"> Liste von Therapeuten herunterladen </button>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import axios from '@/core/api'

export default defineComponent({
    setup() {
        const forceDownload = (response, fileName) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
        }
           const downloadWithAxios = () => {
            var fileName = "Therapeuten.pdf"
               axios({
        method: 'post',
        url: './therapists/list_therapists_pdf/',
        responseType: 'arraybuffer',
      }).then(response => {
                forceDownload(response, fileName)
            })
        }

        return {
            downloadWithAxios
        }
    },
})
</script>
