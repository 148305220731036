const getters = {
  CATEGORIES: state => {
    return state.therapist.categories.filter((item) => item._destroy == "0")
  },
  CATEGORIES_ALL: state => {
    return state.therapist.categories
  },
  THERAPIST: state => {
    return state.therapist.therapist
  },
  REGION: state => {
    return state.therapist.therapist.region
  },
}

export default getters