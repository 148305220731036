<template>
  <div class="alert alert-success" role="alert"
    :variant="variant"
    dismissible
  >
    <h4
      class="alert-heading"
    >
      {{ title }}
    </h4>
    <hr>
    <div v-if="backToLogin"><a href='/'>Zurück zur Startseite</a></div>
  </div>
</template>
<script>
export default {
  name: 'Success',
  props: {
    title: { type: String, default: '' },
    variant: { type: String, default: 'success' },
    backToLogin:  { type: Boolean, default: false },
  }
}
</script>