<template>
  <div>
    <success
      title="Passwort erfolgreich geändert."
      v-if="SHOW_SUCCESS == 1"
      :backToLogin="true"
    />

    <div v-if="TOKEN_VALID" class="container p-5">
      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
          <div class="card text-center">
            <h5 class="card-header">Passwort zurücksetzen</h5>
            <form
              class="needs-validation p-5"
              novalidate="true"
              @submit.prevent="submit"
            >
              <div class="input-group mb-3">
                <input
                  v-model="password1"
                  type="password"
                  :class="
                    'form-control ' +
                    (PASSWORD1_FEEDBACK != '' ? 'is-invalid' : '')
                  "
                  placeholder="Neues Passwort"
                  required
                  @keydown="handleInput"
                />
                <div class="invalid-feedback">
                  {{ PASSWORD1_FEEDBACK }}
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  v-model="password2"
                  type="password"
                  :class="
                    'form-control ' +
                    (PASSWORD2_FEEDBACK != '' ? 'is-invalid' : '')
                  "
                  placeholder="Passwort hier bestätigen"
                  required
                  @keydown="handleInput"
                />
                <div class="invalid-feedback">
                  {{ PASSWORD2_FEEDBACK }}
                </div>
              </div>
              <button type="submit" class="btn btn-primary">Absenden</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-3"></div>
    </div>

    <div v-if="!TOKEN_VALID" class="container p-5">
      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
          <div class="card text-center">
            <h5 class="card-header">Der Passwort-Link ist abgelaufen.</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore, mapGetters } from "vuex";
import Success from "@/core/components/Success";

export default defineComponent({
  components: {
    Success,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const password1 = ref("");
    const password2 = ref("");
    const token = route.params.user_token;
    onMounted(() => {
      store.dispatch("User/PASSWORD_RECOVERY_CHECK_TOKEN", { token: token });
    });

    const submit = () => {
      store.dispatch("User/PASSWORD_RECOVERY_SET_PASSWORD", {
        token: token,
        password1: password1.value,
        password2: password2.value,
      });
    };

    const handleInput = (event) => {
      if (event.getModifierState("CapsLock")) {
        store.commit(
          "User/SET_OLD_PASSWORD_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
        store.commit(
          "User/SET_PASSWORD1_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
        store.commit(
          "User/SET_PASSWORD2_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
      } else {
        clearFeedback();
      }
    };

    const clearFeedback = () => {
      store.commit("User/SET_OLD_PASSWORD_FEEDBACK", "");
      store.commit("User/SET_PASSWORD1_FEEDBACK", "");
      store.commit("User/SET_PASSWORD2_FEEDBACK", "");
    };

    return {
      password1,
      password2,
      submit,
      handleInput,
    };
  },
  computed: {
    ...mapGetters({
      TOKEN_VALID: "User/TOKEN_VALID",
      PASSWORD1_FEEDBACK: "User/PASSWORD1_FEEDBACK",
      PASSWORD2_FEEDBACK: "User/PASSWORD2_FEEDBACK",
      SHOW_SUCCESS: "User/SHOW_SUCCESS",
    }),
  },
});
</script>
