import { initialScore } from './state'

const mutations = {
  CREATE_SCORE (state) {
    state.category.scores.push( initialScore() )
  },

  UPDATE_SCORE (state, payload) {
    state.category.scores.filter((item) => item._destroy == "0")[payload.index].fk_score = payload.fk_score
  },
  DELETE_SCORE (state, payload) {
    state.category.scores.filter((item) => item._destroy == "0")[payload.index]._destroy = true
  },
  SET_CATEGORY: (state, payload) => {
    state.category.category = payload
    state.category.scores = payload.scores
  },
  SET_CATEGORY_NAME: (state, payload) => {
    state.category.category.name = payload
  },
}

export default mutations