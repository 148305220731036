const initialMeasurement = function () {
  return {
    id: null,
    is_selected: false,
    fk_user: null,
    fk_creator: null,
    fk_modifier: null,
    show_success: 0,
    therapy_arm: 0,
    can_edit: true,
    form_completed_at: Math.floor(Date.now() / 1000),
    created_at: Math.floor(Date.now() / 1000),
    values: new Array(62).fill(0),

    lc29_60_text: null,
    lc29_61_text: null,
    lc29_62_text: null,
  }
}

const initialValidation = function() {
  let validation = []
  for(let i = 1; i <= 59; i++){
    let index_str = "q" + i + "ok"
    validation[index_str] = true
  }
  return validation
}

const state = {
  measurement: {
    measurement: initialMeasurement(),
    measurements: [],
    validation: initialValidation(),
    templates: [],
    template: '',
    canCreateMeasurement: null,
    nextMeasurement: null,
    user_id: null,
    measurements_overview: [],
    profile: "",
    gotworseitems: [],
    new_count: 0,
    not_seen: [],
  }
}

export { state, initialMeasurement, }