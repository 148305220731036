<template>
<div class="container p-5">
  <div class="card infocard">
                      <success
      title="Email erfolgreich gesendet"
      v-if="CONTACT_SUCCESS == 1"
    />
    <b>Kontakt</b>
<p>Bei Fragen oder Anmerkungen kontaktieren Sie gerne unsere Studienzentrale:</p>

<b>Anschrift:</b>
<p>Tumorzentrum Regensburg<br>
Zentrum für Qualitätssicherung und Versorgungsforschung<br>
an der Fakultät für Medizin der Universität Regensburg<br>
Studienzentrale für Lebensqualität<br>
Am BioPark 9<br>
93053 Regensburg</p>

<b>Ansprechpartner</b>
<p>Dr. Florian Gürtler<br>
Telefon: +49 (0) 941 / 943-4092 oder -1803 (Sekretariat)<br>
E-Mail: lebensqualitaet.lepalumo&#64;ur.de</p>

    <form  class="needs-validation p-5"
            novalidate="true"
            @submit.prevent="submit">
      <div class="input-group mb-3 mt-2">
        <label for="name" class="col-sm-2">Name:</label>
        <input type="text" class="form-control col-sm-10" id="name" 
        aria-describedby="name" placeholder="Name" 
        v-model="name"
        :class="'form-control ' + (NAME_FEEDBACK != '' ? 'is-invalid' : '')">
               <div class="invalid-feedback">
            {{ NAME_FEEDBACK }}
          </div>
      </div>
      <div class="input-group mb-3 mt-2">
        <label for="email" class="col-sm-2">Email-Adresse:</label>
        <input type="text" class="form-control col-sm-10" id="email" 
        aria-describedby="email" placeholder="Email" v-model="email"
        :class="'form-control ' + (EMAIL_FEEDBACK != '' ? 'is-invalid' : '')">
                <div class="invalid-feedback">
            {{ EMAIL_FEEDBACK }}
          </div>
      </div>
      <div class="input-group mb-3 mt-2">
        <label for="betreff" class="col-sm-2">Betreff:</label>
        <input type="text" class="form-control col-sm-10" id="betreff" 
        aria-describedby="betreff" placeholder="Betreff" v-model="title"
        :class="'form-control ' + (TITLE_FEEDBACK != '' ? 'is-invalid' : '')">
            <div class="invalid-feedback">
            {{ TITLE_FEEDBACK }}
          </div>
      </div>
      <div class="input-group mb-3 mt-2">
        <label for="message" class="col-sm-2">Nachricht:</label>
        <textarea type="text" class="form-control col-sm-10" id="message" 
        aria-describedby="nachricht" placeholder="Nachricht" v-model="message"
        :class="'form-control ' + (MESSAGE_FEEDBACK != '' ? 'is-invalid' : '')"/>
              <div class="invalid-feedback">
            {{ MESSAGE_FEEDBACK }}
          </div>
      </div>
        <div class="input-group mb-3 mt-2">
          <p class="col-sm-12">Damit wir nicht so viele SPAM-Nachrichten erhalten, bitten wir Sie, die folgende Frage zu beantworten:</p>
        <label v-if="isMounted" for="captcha" class="col-sm-2">
          {{QUIZ?.question}}
        </label>
        <input type="text" class="form-control col-sm-10" id="captcha" 
        aria-describedby="captcha" placeholder="Bitte geben Sie eine Antwort ein" v-model="answer_given"
        :class="'form-control ' + (CAPTCHA_FEEDBACK != '' ? 'is-invalid' : '')">
           <div class="invalid-feedback">
            {{ CAPTCHA_FEEDBACK }}
          </div>
      </div>
      <button type="submit" class="btn btn-primary">
        Absenden
      </button>
    </form>
  </div>
</div>
</template>
<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useStore, mapGetters } from 'vuex'
import Success from "@/core/components/Success";

export default defineComponent({
    setup() {
        const store = useStore()
        const name = ref(null)
        const email = ref(null)
        const title = ref(null)
        const message = ref(null)
        const answer_given = ref(null)
        const quiz_id = ref(null)
        const quiz = computed(() => store.getters["User/QUIZ"])
        const isMounted = ref(false)

        const submit = () => {
          store.dispatch("User/CONTACT", {name: name.value, email: email.value, title: title.value, message: message.value, answer_given: answer_given.value, quiz_id: quiz.value.id})
        }

        onMounted(() => {
          store.dispatch("User/GET_QUIZ")
          isMounted.value = true
        }) 

        return {
          name, email, title, message,answer_given,quiz_id, submit, isMounted
        }
    },
    components: {
      Success
    },
    computed: {
          ...mapGetters({QUIZ: "User/QUIZ", 
          NAME_FEEDBACK: "User/NAME_FEEDBACK",
          TITLE_FEEDBACK: "User/TITLE_FEEDBACK",
          EMAIL_FEEDBACK: "User/EMAIL_FEEDBACK",
          MESSAGE_FEEDBACK: "User/MESSAGE_FEEDBACK",
          CAPTCHA_FEEDBACK: "User/CAPTCHA_FEEDBACK",
          CONTACT_SUCCESS: "User/CONTACT_SUCCESS"}),
    }
})
</script>
