<template>
  <div>
    <div class="card col bigcard">
      <div class="card-body">
        <div class="input-group mb-3">
          <label>Name</label>
        <input type="text" placeholder="Kategorie" aria-label="Kategorie" v-model="category_name"/>
        </div>
        <div class="row">
          <table class="mb-3">
            <div v-for="(row, index) in SCORES" :key="row.id">
              <div class="input-group dropdown">
              <label>Score</label>
              <select class="form-select" :value="row.fk_score" @change="updateScore(index, $event.target.value)">
              <option
                v-for="s in SCORES_LIST.scores"
                :key="s.id"
                :value="s.id">
                {{s.name}}
              </option>
              </select>
              <button class="btn btn-primary" @click="deleteScore(index)">
                -
              </button>
              </div>
            </div>
          </table>
        </div>
        <div class="row mb-3">
            <button class="btn btn-primary button-lg" @click="addScore()">
              +
            </button> 
        </div>
        <div class="row mb-3 button-lg">
          <button  v-if="isNewRoute" type="button" class="btn btn-primary" @click="addCategory">Neue kategorie</button>
        <button  v-if="!isNewRoute" type="button" class="btn btn-primary" @click="editCategory">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore, mapGetters } from 'vuex'

export default defineComponent({
    setup() {
        const store = useStore()
        const route = useRoute()
         const category = computed(() => store.getters["Category/CATEGORY"])
         const scores = computed(() => store.getters["Category/SCORES_ALL"])
          const category_name = computed(
              {
                get:() => {
                  return category.value.name
                },
                set:(value) => {
                  store.commit("Category/SET_CATEGORY_NAME", value)
                }
              })

             const categoryId = route.params.categoryId


        const editCategory = () => {
          store.dispatch("Admin/EDIT_CATEGORY", {id: category.value.id, name: category.value.name, scores: scores.value})
        }

             const addCategory = () => {
                  store.dispatch("Admin/ADD_CATEGORY", {category: category.value, scores: scores.value})
        }


        const addScore = () => {
          store.commit("Category/CREATE_SCORE")
        }

              const updateScore = (index, fk_score) => {
          store.commit("Category/UPDATE_SCORE", {index: index, fk_score: fk_score})
        }

        const deleteScore = (index) => {
          store.commit('Category/DELETE_SCORE', { index: index } )
        }

        const isNewRoute = computed(() => {return route.params.categoryId == "new"} )

        watch(() => route.params.categoryId, async newId => {
              if(newId == "new"){
                store.dispatch('Category/CREATE_CATEGORY')
                return
            }else{
                store.dispatch('Category/LOAD_CATEGORY', { category_id: newId })
            }
        })
    
        onMounted(()=> {
            store.dispatch("Admin/LIST_ALL_SCORES")
            if(categoryId == "new"){
                store.dispatch('Category/CREATE_CATEGORY')
                return
            }else{
                store.dispatch('Category/LOAD_CATEGORY', { category_id: route.params.categoryId })
            }
        })
        return {
            editCategory, scores, categoryId,category_name,addScore, updateScore, deleteScore,isNewRoute, addCategory
        }
    },
    computed: {
          ...mapGetters({SCORES_LIST: "Admin/SCORES", SCORES: "Category/SCORES"}),
          
    }
})
</script>
<style scoped>
label{
  margin-right: 10px;
  width: 100px;
  text-align: right;
}
.dropdown{
  width: 400px;
}
input{
  width: 400px;
}
button-lg{
  width: 400px;
}
</style>