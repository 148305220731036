import modules from './modules'
import { createLogger } from 'vuex'
import { createStore } from 'vuex'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  namespaced: true,
  modules,
  actions: {
    async reset({commit}) {
      // resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
      // await new Promise(resolve => setTimeout(resolve, 5000)); // 5 sec
      return true
    }
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

export default store

