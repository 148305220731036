<template>
    <div class="modal fade" 
        ref="setPasswordModal" 
        tabIndex="-1" 
        aria-labelledby="editUserModalLabel"
        :class="{show: show, 'd-block': show}"
        aria-hidden="true"
        :key="key"
        > 
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title col" id="editUserModalLabel">Passwort setzen</h5>
                </div>
                    <error-list :errors="ERRORS" />
                        <success title="Passwort erfolgreich geändert." v-if="SHOW_SUCCESS == 1" />
          <form
            class="needs-validation p-5"
            novalidate="true"
            @submit.prevent="submit"
          >
            <div class="input-group mb-3">
              <input
                v-model="password1"
                :class="
                  'form-control ' +
                  (PASSWORD1_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Neues Passwort"
                type="password"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ PASSWORD1_FEEDBACK }}
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-model="password2"
                :class="
                  'form-control ' +
                  (PASSWORD2_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Passwort Wiederholen"
                type="password"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ PASSWORD2_FEEDBACK }}
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Absenden</button>
          </form>
                <div class="modal-footer"><button class="btn btn-secondary" @click="close()">Schließen</button>
                        <button type="button" class="btn btn-primary" @click="submit()">Änderungen speichern</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import ErrorList from "@/core/components/ErrorList";
import Success from "@/core/components/Success";
export default defineComponent({
    data() {
        return {
            password1: '',
            password2: '',      
            key: 0
        }
    },
    props: {
        show: {type: Boolean, default: false},
        user: {type: Object, default: null},
    },

    computed:{
            ...mapGetters({
      ERRORS: "Admin/ERRORS",
      SHOW_SUCCESS: "Admin/SHOW_SUCCESS",
      PASSWORD1_FEEDBACK: "Admin/PASSWORD1_FEEDBACK",
      PASSWORD2_FEEDBACK: "Admin/PASSWORD2_FEEDBACK",
    }),
    },

    methods: {
        submit(){
        this.$store.dispatch("Admin/SET_PASSWORD_FOR_USER", {
        user: this.user.id,
        password1: this.password1,
        password2: this.password2,
      })},

        close(){
            this.$emit("modalClosed")
        },
    },
    components:{
        ErrorList,
        Success
    },

    watch: {
        'show' : function(){
            this.key += 1
        }
    }
})
</script>
