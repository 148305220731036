<template>
  <div class="card infocard">
  <kontakt></kontakt>
   <button class="btn btn-primary" @click="back">Zurück zum Login</button>
</div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import Kontakt from '@/modules/users/components/Kontakt'

export default defineComponent({
  components: { Kontakt },
    setup() {
      const router = useRouter()
      const back = () => {
        router.push("/login")
      }
      return {
        back
      }
    },
})
</script>
