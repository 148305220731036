<template>
<div class="scroll">
  <div
    class="modal fade"
    ref="measurementListModal"
    tabIndex="-1"
    aria-labelledby="measurementListModalLabel"
    :class="{ show: show, 'd-block': show }"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title col" id="measurementListModalLabel">
            Messungen für Patient
          </h5>
          <button
            type="button"
            class="btn-close col-2"
            @click="close()"
            aria-label="Schließen"
          ></button>
        </div>
        <div class="modal-body">
          <div class="card col measurement-modal">
            <div class="card-body">
              <tr>
                <td class="align-top">
              <h5 class="card-title">Messungen</h5>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Auswahl</th>
                  </tr>
                </thead>
                <tbody v-if="MEASUREMENTS_LIST">
                  <tr
                    v-for="measurement in MEASUREMENTS_LIST"
                    :key="measurement.id"
                  >
                    <td>
                      {{
                        new Date(
                          measurement.form_completed_at
                        ).toLocaleDateString("de-DE", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      }}
                    </td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :checked="measurement.is_selected"
                          id="flexCheckChecked"
                          v-if="THERAPY_ARM == 1"
                          :disabled="!measurement.has_valid_graph"
                          @change="checkboxChanged(measurement, $event)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="input-group">
              <button 
                type="button"
                class="btn btn-primary me-3"
                @click="this.downloadWithAxios()"
                v-if="MEASUREMENTS_LIST.length > 0 &&  THERAPY_ARM == 1"
                :disabled="
                  !MEASUREMENTS_LIST.filter(
                    (measurement) => measurement.is_selected
                  ).length > 0
                "
              >
                Profil drucken
              </button>
              <!--
              <label class="me-3">Profilauswahl</label>
              <select class="form-select" :value="template.id" @change="updateTemplate($event.target.value)">
              <option
                v-for="t in TEMPLATES_LIST.templates"
                :key="t.id"
                :value="t.id"
                >
                {{t.name}}
              </option>
              </select>
              -->
              </div>
              </td>
              <td>
                <img v-if="imageSrc" :src="'data:image/jpeg;base64, ' + imageSrc" />
                <div v-if="!imageSrc && THERAPY_ARM == 1">Bitte wählen Sie Messungen zur Profildarstellung aus</div>
              </td>
              </tr>
            </div>
            <div v-if="THERAPY_ARM != 1" class="card-body">
              <h5 class="card-title">
                Profile können nur im Interventionsarm erstellt werden
              </h5>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="close()">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { mapGetters, useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "@/core/api";

export default defineComponent({
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const patientId = ref(props.patientId);
    const template = computed(() => store.getters["Measurements/TEMPLATE"]);
    const imageSrc = ref(null);
    
    const measurements_list = computed(
      () => store.getters["Measurements/MEASUREMENTS"]
    );

    const patientIdChanged = () => {
      store.dispatch("Measurements/LOAD_PATIENT_MEASUREMENT", {
        id: patientId.value,
      });
    };

    const updateTemplate = (value) => {
      store.commit("Measurements/SET_TEMPLATE", {id: value})
    }

    onMounted(() => {
      patientIdChanged();
      store.dispatch("Measurements/LIST_ALL_TEMPLATES")
      setTimeout(() => {
      imageSrc.value = loadImage();
      }, 2000)
    });

    const display_measurement = (id) => {
      router.push("/measurements/" + id);
    };

    const forceDownload = (response, fileName) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    };

    const loadImage = async () => {
      let indexes = measurements_list.value
        .filter((measurement) => measurement.is_selected)
        .map((measurement) => measurement.id);
      var url = "/measurements/profilelive";
      const response = await axios.post(url, { indexes: indexes}).catch(() => {
        imageSrc.value = "";
      });
      if (response && response.status == 200) {
        imageSrc.value = response.data;
      }
    };

    const checkboxChanged = (measurement, event) => {
      store.commit("Measurements/SET_IS_SELECTED", {
        measurement: measurement,
        is_selected: event.srcElement.checked,
      });
      imageSrc.value = loadImage();
    };

    const create_measurement = () => {
      store.commit("Measurements/SET_FK_USER", { value: patientId.value });
      router.push("/measurements/new");
    };

    const close = () => {
      context.emit("modalClosed");
    };

    const downloadWithAxios = () => {
      let indexes = measurements_list.value
        .filter((measurement) => measurement.is_selected)
        .map((measurement) => measurement.id);
      var fileName = patientId.value + ".pdf";
      axios({
        method: "post",
        url: "/users/" + fileName,
        responseType: "arraybuffer",
        data: {indexes: indexes, template: {id: 1}/*template.value*/},
      }).then((response) => {
        forceDownload(response, fileName);
      });
    };

    return {
      display_measurement,
      forceDownload,
      checkboxChanged,
      create_measurement,
      patientIdChanged,
      close,
      downloadWithAxios,
      template,
      updateTemplate,
      loadImage,
      imageSrc
    };
  },
  props: {
    show: { type: Boolean, default: false },
    patientId: { type: Number, default: null },
  },
  computed: {
    ...mapGetters({
      ERRORS: "Measurements/ERRORS",
      ERRORS2: "Measurements/ERRORS2",
      MEASUREMENTS_LIST: "Measurements/MEASUREMENTS",
      THERAPY_ARM: "Measurements/THERAPY_ARM",
      TEMPLATES_LIST: "Measurements/TEMPLATES_LIST"
    }),
    measurements_list_count_positive() {
      return this.MEASUREMENTS_LIST.length > 0;
    },
  },
  watch: {
    patientId: "patientIdChanged",
  },
});
</script>
<style scoped>
.modal-dialog{
  overflow-y: scroll;
  height: 80vh;  
}
</style>