import { createRouter, createWebHistory } from 'vue-router'
import userRoutes from '@/modules/users/router'
import adminRoutes from '@/modules/admins/router'
import measurementRoutes from '@/modules/measurements/router'
import store from '@/store'

const routes = [
  ...userRoutes,
  ...adminRoutes,
  ...measurementRoutes
]

const base_dir = process.env.NODE_ENV === 'production' ? '/lepalumo/' : '/'
const router = createRouter({
  history: createWebHistory(base_dir),
  base: base_dir,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires authentication, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('is_logged_in') || localStorage.getItem('is_logged_in').toString() !== '1') {
      if(from.matched.some(record => record.path != '/login') && to.matched.some(record => record.path != '/')){
        store.commit('User/SET_ERRORS', ['Um auf diese Seite zugreifen zu können, müssen Sie eingeloggt sein. Bitte melden Sie sich an.'])
      }
      next({ name: 'Login' } )
    }
    else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
  // next()
})

export default router
