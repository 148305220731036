<template>
  <div class="DefaultLayout">
    <div id="background">
      <img src="@/assets/bg.jpg" class="stretch" alt="" />
    </div>
    <slot/>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
    }
})
</script>
<style>
  #background{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
  }
  .stretch {
    width: 100%;
    height: 100%;
  }
</style>