<template>
<div class="scroll">
  <div
    class="modal fade"
    ref="notSeenModal"
    tabIndex="-1"
    aria-labelledby="notSeenModalLabel"
    :class="{ show: show, 'd-block': show }"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title col" id="notSeenModalLabel">
            Neue Messungen
          </h5>
          <button
            type="button"
            class="btn-close col-2"
            @click="close()"
            aria-label="Schließen"
          ></button>
        </div>
        <div class="modal-body">
          <div class="card col measurement-modal">
            <div class="card-body">
              <h5 class="card-title">Messungen</h5>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Datum</th>
                    <th scope="col">Patient</th>
                    <th scope="col">Ver-schlech-terung</th>
                    <th scope="col">Einsehen</th>
                    <th scope="col">Download</th>
                  </tr>
                </thead>
                <tbody v-if="MEASUREMENTS_LIST">
                  <tr
                    v-for="measurement in MEASUREMENTS_LIST"
                    :key="measurement.id"
                  >
                    <td>
                      {{
                        new Date(
                          measurement.form_completed_at
                        ).toLocaleDateString("de-DE", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      }}
                    </td>
                    <td>
                      {{ measurement.first_name }} {{ measurement.last_name }}
                    </td>
                    <td>
                       <p v-if="measurement.got_worse == true">ja</p> 
                    </td>
    
                    <td>
                    <button 
                type="button"
                class="btn btn-primary me-3"
                @click="gotoProfile(measurement.id)">
                Ein-sehen
              </button>
              </td>
                      <td>
                    <button 
                type="button"
                class="btn btn-primary me-3"
                @click="this.downloadWithAxios(measurement.pat_id, measurement.id)">
                Down-load
              </button>
            </td>
                  </tr>
                </tbody>
              </table>
              <div class="input-group">
              <!--
              <label class="me-3">Profilauswahl</label>
              <select class="form-select" :value="template.id" @change="updateTemplate($event.target.value)">
              <option
                v-for="t in TEMPLATES_LIST.templates"
                :key="t.id"
                :value="t.id"
                >
                {{t.name}}
              </option>
              </select>
              -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="close()">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { mapGetters, useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "@/core/api";

export default defineComponent({
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const patientId = ref(props.patientId);
    const template = computed(() => store.getters["Measurements/TEMPLATE"]);
    
    const measurements_list = computed(
      () => store.getters["Measurements/NOT_SEEN"]
    );

    const updateTemplate = (value) => {
      store.commit("Measurements/SET_TEMPLATE", {id: value})
    }

    onMounted(() => {
      store.dispatch("Measurements/LIST_ALL_TEMPLATES")
    });

    const display_measurement = (id) => {
      router.push("/measurements/" + id);
    };

    const gotoProfile = (measurementId) => {
      router.push("/profile/" + measurementId);
    }

    const forceDownload = (response, fileName) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    };

    const checkboxChanged = (measurement, event) => {
      store.commit("Measurements/SET_IS_SELECTED", {
        measurement: measurement,
        is_selected: event.srcElement.checked,
      });
    };

    const create_measurement = () => {
      store.commit("Measurements/SET_FK_USER", { value: patientId.value });
      router.push("/measurements/new");
    };

    const close = () => {
      context.emit("modalClosed");
    };

    const downloadWithAxios = (patientId, measurementId) => {
      let indexes = [measurementId];
      var fileName = patientId + ".pdf";
      axios({
        method: "post",
        url: "/users/" + fileName,
        responseType: "arraybuffer",
        data: {indexes: indexes, template: {id: 1}/*template.value*/},
      }).then((response) => {
        forceDownload(response, fileName);
      });
    };

    onMounted(() => {
      store.dispatch("Measurements/LOAD_NEW_MEASUREMENTS");
    });

    return {
      display_measurement,
      forceDownload,
      checkboxChanged,
      create_measurement,
      close,
      downloadWithAxios,
      template,
      updateTemplate,
      measurements_list,
      gotoProfile
    };
  },
  props: {
    show: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      ERRORS: "Measurements/ERRORS",
      ERRORS2: "Measurements/ERRORS2",
      MEASUREMENTS_LIST: "Measurements/NOT_SEEN",
      THERAPY_ARM: "Measurements/THERAPY_ARM",
      TEMPLATES_LIST: "Measurements/TEMPLATES_LIST"
    }),
    measurements_list_count_positive() {
      return this.MEASUREMENTS_LIST.length > 0;
    },
  }
});
</script>
<style scoped>
.modal-dialog{
  overflow-y: scroll;
  height: 80vh;  
}
</style>