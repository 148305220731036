import user from "@/store/modules/users"
import admin from "@/store/modules/admins"
import measurement from "@/store/modules/measurements"
import therapist from "@/store/modules/therapists"
import categories from "@/store/modules/categories"

const modules = {
  User: user,
  Admin: admin,
  Measurements: measurement,
  Therapist: therapist,
  Category: categories
}

export default modules
