const initialState = function () {
  return {
    categories: [],
    therapist: {},
  }
}

const initialCategory = function () {
  return {
    id: null,
    _destroy: 0,
    fk_category: 0
  }
}

const initialTherapist = function() {
  return {
    title: '',
    last_name: '',
    first_name: '',
    street: '',
    email: '',
    phone: '',
    postal_code: '',
    city: '',
    categories: [],
    memo: '',
    region: 0
  }
}


const state = {
  therapist: initialState(),
}

export { state, initialState, initialCategory, initialTherapist}