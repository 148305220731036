<template>
  <div>
    <div>
      <div class="card infocard">
        <h5>
        <p>{{  PERSON_STRING.person_string_full }}</p>
        <p>vielen herzlichen Dank für Ihre Teilnahme an der LePaLuMo-Studie. Wir schätzen Ihr Engagement sehr!</p>
        <p>Sie befinden sich aktuell in der LePaLuMo-Anwendung. Hier haben Sie folgende Möglichkeiten:</p>
        <ul>
          <li>Ausfüllen Ihrer nächsten Lebensqualitäts-Befragung (unter "Nächste LQ Messung")</li>
          <li>Einsicht in Ihre Ergebnisse von bereits ausgefüllten Fragebögen (unter "Messungen")</li>
          <li>Zugriff auf Kontaktdaten von VersorgerInnen der regionalen Versorgungsnetzwerke (unter "Therapeuten")</li>
        </ul>
        <p>
          Ihre kontinuierliche Beteiligung kann eine zielgerichtetere Nachsorge ermöglichen sowie die Kommunikation zwischen Ihnen und Ihrem Arzt bzw. Ihrer Ärztin fördern.
          Die bereitgestellten Daten helfen den ÄrztInnen, Ihre individuellen Bedürfnisse besser zu verstehen, gezielt auf Ihre Symptome einzugehen und die Behandlung anzupassen.
          Sie können so aktiv zur Gestaltung Ihres Genesungsprozesses beitragen und auf Verbesserungsmöglichkeiten hinweisen.
        </p>
        <p>
          Das Ausfüllen des Fragebogens wird in etwa 10 Minuten Zeit in Anspruch nehmen. 
        </p>
        <p>
          Sollten Sie Fragen zur Anwendung haben, können Sie sich jederzeit an die Studienzentrale (unter "Kontakt") wenden.
        </p>
        <p>
          Wir danken Ihnen nochmals herzlich für Ihre Teilnahme und Ihr wertvolles Engagement.
          Für Rückfragen stehen wir Ihnen jederzeit herzlich gerne zur Verfügung. 
        </p>
        <p>Mit freundlichen Grüßen, </p>
        <p>Ihr LePaLuMo-Forschungsteam</p>
        </h5>
      </div>
      <div v-if="CAN_CREATE_MEASUREMENT == false" class="card infocard col">
        <div class="card-body">
          <h5 class="card-title">Vielen Dank für die Teilnahme an der LePaLuMo-Studie. 
            Derzeit können Sie leider keine Messung anlegen. {{NEXT_MEASUREMENT}}</h5>
        </div>
      </div>
      <div v-if="CAN_CREATE_MEASUREMENT == true" class="card bigcard col">
        <div class="card-body">
          <h5 class="card-title">Nächste LQ Messung</h5>
          <a href="#" class="btn btn-primary" @click="create_measurement"
            >Hier klicken</a
          >
        </div>
      </div>
      <div class="card infocard col">
        <button class="btn btn-primary" @click="therapists">Therapeutenliste</button>
      </div>
      <div class="card infocard col">
        <div class="card-body">
          <tr>
        <td class="align-top">
          <h5 class="card-title">Messungen</h5>
          <div>
            <div class="card-body">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Ausgefüllt am</th>
                    <th scope="col">Anzeigen</th>
                    <th scope="col">Info</th>
                             <th scope="col">Auswählen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="measurement in MEASUREMENTS_LIST"
                    :key="measurement.id"
                  >
                    <td>
                      {{
                        new Date(
                          measurement.form_completed_at
                        ).toLocaleDateString("de-DE", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      }}
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="display_measurement(measurement.id)"
                        v-if="measurement.editUntil"
                      >
                        >
                      </button>
                    </td>
                           <td>
                     {{ measurement.editUntil }}
                    </td>
                                 <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          :checked="measurement.is_selected"
                          v-if="MEASUREMENTS_LIST.length > 0 &&  THERAPY_ARM == 1"
                          :disabled="!measurement.has_valid_graph"
                          @change="checkboxChanged(measurement, $event)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
                                <button 
                type="button"
                class="btn btn-primary me-3"
                @click="this.downloadWithAxios()"
                v-if="MEASUREMENTS_LIST.length > 0 &&  THERAPY_ARM == 1"
                :disabled="
                  !MEASUREMENTS_LIST.filter(
                    (measurement) => measurement.is_selected
                  ).length > 0
                "
              >
                Profil drucken
              </button>
            </div>
          </div>
        </td>
      <td><img v-if="imageSrc" :src="'data:image/jpeg;base64, ' + imageSrc" />
        <div v-if="!imageSrc && THERAPY_ARM == 1">Bitte wählen Sie Messungen zur Profildarstellung aus</div>
        <div class="mt-3" v-if="gotworsecount > 0">
        <p>In der oben abgebildeten Grafik (LQ-Profil) können Sie Ihre Ergebnisse aus der Lebensqualitäts-Befragung einsehen.
          Ihre Ergebnisse deuten in den Bereichen:
          <ul>
            <li v-for="item in this.gotworseitems" :key="item">{{ item }}</li>
          </ul> aufgrund eines Wertes unter "50" auf eine "reduzierte" Lebensqualität hin. 
Bitte nutzen Sie die Informationen und scheuen Sie sich nicht, das LQ-Profil mit Ihrem behandelnden Arzt bzw. Ihrer behandelnden Ärztin zu besprechen. 
Möglicherweise werden in diesem Gespräch weiterführende Therapieangebote erörtert und Ihr Arzt/Ihre Ärztin kann entscheiden, ob die Kontaktaufnahme mit regionalen VersorgerInnen für Sie sinnvoll ist.</p>
      
      </div>
      </td>
      </tr>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import axios from "@/core/api";

export default defineComponent({
  data() {
    return {
      imageSrc: null,
      gotworseitems: []
    }
  },
  computed: {
    ...mapGetters({
      ERRORS: "Measurements/ERRORS",
      ERRORS2: "Measurements/ERRORS2",
      MEASUREMENTS_LIST: "Measurements/MEASUREMENTS",
      THERAPY_ARM: "Measurements/THERAPY_ARM",
      CAN_CREATE_MEASUREMENT: "Measurements/CAN_CREATE_MEASUREMENT",
      NEXT_MEASUREMENT: "Measurements/NEXT_MEASUREMENT",
      USER_ID: "Measurements/USER_ID",
      PERSON_STRING: "User/PERSON_STRING"
    }),
  
    gotworsecount() {
      return this.gotworseitems.length
    }
  },
  mounted() {
    this.$store.dispatch("Measurements/LOAD_LIST");
    this.$store.dispatch("User/GET_PERSON_STRING")
    setTimeout(() => {
      this.imageSrc = this.loadImage();
    }, 2000)
  },
  methods: {
    create_measurement() {
      this.$store.dispatch("Measurements/SET_FK_USER", { value: null });
      this.$router.push("/measurements/new");
    },
    display_measurement(id) {
      this.$router.push("/measurements/" + id);
    },
    checkboxChanged(measurement, event){
      this.$store.commit("Measurements/SET_IS_SELECTED", {
        measurement: measurement,
        is_selected: event.srcElement.checked,
      });
      this.imageSrc = this.loadImage();
    },

    downloadWithAxios(){
      let indexes = this.MEASUREMENTS_LIST
        .filter((measurement) => measurement.is_selected)
        .map((measurement) => measurement.id);
      var fileName = this.USER_ID + ".pdf";
      axios({
        method: "post",
        url: "/users/" + fileName,
        responseType: "arraybuffer",
        data: {indexes: indexes, template: {id: 1}/*template.value*/},
      }).then((response) => {
        this.forceDownload(response, fileName);
      });
    },
    forceDownload(response, fileName){
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    },
    therapists(){
      this.$router.push("/users/therapists");
    },
    async loadImage() {
      this.$store.commit("Measurements/SET_GOTWORSE_ITEMS", []);
      let indexes = this.MEASUREMENTS_LIST
        .filter((measurement) => measurement.is_selected)
        .map((measurement) => measurement.id);
      var url = "/measurements/profilelive";
      const response = await axios.post(url, { indexes: indexes}).catch(() => {
        this.imageSrc = "";
      });
      if (response && response.status == 200) {
        this.imageSrc = response.data;
      }

      var url2 = "/measurements/gotworseitems";
      const response2 = await axios.post(url2, { indexes: indexes}).catch(() => {
        this.gotworseitems = [];
      });
      if (response2 && response2.status == 200) {
        this.gotworseitems = response2.data.items;
      }
    }

  },
});
</script>
<style>
.btn {
  max-width: 500px;
}
</style>