const initialState = function () {
  return {
    scores: [],
    category: initialCategory(),
  }
}

const initialScore = function () {
  return {
    id: null,
    _destroy: 0,
    fk_score: 0
  }
}

const initialCategory = function() {
  return {
    name: '',
    scores: []
  }
}


const state = {
  category: initialState(),
}

export { state, initialState, initialScore, initialCategory}