<template>
<div class="container p-5">
  <div class="card infocard">
    <b>Impressum</b>  
  <p><b>Tumorzentrum Regensburg<br>
  Zentrum für Qualitätssicherung und Versorgungsforschung
an der Fakultät für Medizin der Universität Regensburg</b><br>
Am BioPark 9<br>
93053 Regensburg</p>
<b>Leitung:</b>
<p>Frau Prof. Dr. med. Monika Klinkhammer-Schalke</p>
<b>Kontakt:</b>
<p>Telefon: +49 (0) 941 / 943 1803<br>
Telefax: +49 (0) 941 / 943 1802<br>
E-Mail: zentrum.tumor@ur.de</p>

<p>Urheberrecht</p>

<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
  </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        
    },
})
</script>
