import api from '@/core/api'
import { initialTherapist } from './state'


/***********
 *  Actions
 ***********/
 const actions = {
  REGISTER: async (context, payload) => {
    context.dispatch("CLEAR_NEW_USER_FEEDBACK")
    try{
    let response = await api.post('/users/register', {study_number: payload.study_number, title: payload.title, user_name: payload.user_name, first_name: payload.first_name, last_name: payload.last_name, sex: payload.sex, email: payload.email, therapy_arm: payload.therapy_arm, user_type: payload.user_type, fk_clinic: payload.fk_clinic, measurement0: payload.measurement0, measurement1: payload.measurement1, measurement2: payload.measurement2, measurement3: payload.measurement3, measurement4: payload.measurement4, measurement5: payload.measurement5, measurement6: payload.measurement6, fk_region: payload.region})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_NEW_USER_SUCCESS", 1)
      context.commit("SET_NEW_USER_FEEDBACK", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data){
        if(error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
        }

        context.commit("SET_NEW_USER_FEEDBACK", error.response.data)
        context.commit("SET_NEW_USER_SUCCESS", 0)
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
  }
  },
  CLEAR_NEW_USER_FEEDBACK: async (context) => {
    context.commit("SET_NEW_USER_SUCCESS", 0)
    context.commit("SET_NEW_USER_FEEDBACK", [])
    context.commit("SET_ERRORS",[])
  },
  LIST_USERS: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/users/list_users/page/' + payload.current_page + '/perPage/' + payload.per_page + "/searchKey/" + payload.search_key)
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_USERS_LIST", response.data)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
},
LIST_ALL_PATIENTS_FOR_CLINIC: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.get('/users/list_all_patients_for_clinic/page/' + payload.current_page + '/perPage/' + payload.per_page + "/fk_clinic/" + payload.fk_clinic + "/searchKey/" + payload.search_key)
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_PATIENTS_LIST", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
LIST_ALL_CLINICS_FOR_PATIENT: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.get('/users/list_all_clinics_for_patient/page/' + payload.current_page + '/perPage/' + payload.per_page + "/fk_patient/" + payload.fk_patient + "/searchKey/" + payload.search_key)
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_CLINICS_LIST", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
EDIT_USER: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/users/edit_user/', {user_name: payload.user_name, user_type: payload.user_type, therapy_arm: payload.therapy_arm})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }else{
      context.commit("SET_ERRORS",[])
      context.commit("SET_EDIT_SUCCESS", true)
      }
    }
  }catch(error){
    if(error.response.status == 422){
      if(error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }
    }else{
      if(error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }
    }
}
},
EDIT_USER_WITH_EMAIL: async (context, payload) => {
  context.commit("SET_EDIT_ERROR",[])
  try{
    let response = await api.post('./users/edit_user2/', {dropped_out_at: payload.dropped_out_at, study_number: payload.study_number, title: payload.title, user_name: payload.user_name, first_name: payload.first_name, last_name: payload.last_name,  sex: payload.sex, email: payload.email, user_type: payload.user_type, therapy_arm: payload.therapy_arm, measurement0: payload.measurement0, measurement1: payload.measurement1, measurement2: payload.measurement2, measurement3: payload.measurement3, measurement4: payload.measurement4, measurement5: payload.measurement5, measurement6: payload.measurement6, fk_region: payload.region})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_EDIT_ERROR",[response.data.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }else{
      context.commit("SET_ERRORS",[])
      context.commit("SET_EDIT_SUCCESS", true)
      }
    }
  }catch(error){
    if(error.response.status == 422){
      if(error.response.data.user.error){
        context.commit("SET_EDIT_ERROR",[error.response.data.user.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }
    }else{
      if(error.response.data.error){
        context.commit("SET_EDIT_ERROR",[error.response.data.user.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }
    }
}
},
SET_FK_CLINIC: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/users/set_fk_clinic/', {clinic: payload.clinic, user: payload.user})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }else{
      context.commit("SET_ERRORS",[])
      context.commit("SET_EDIT_SUCCESS", true)
      setTimeout(() => {
        context.commit("SET_EDIT_SUCCESS", false)
      }, 3000)
      }
    }
  }catch(error){
    if(error.response.status == 422){
      if(error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }
    }else{
      if(error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
        context.commit("SET_EDIT_SUCCESS", false)
      }
    }
}

},
SEND_PASSWORD_LINK: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/users/send_password_link/', {email: payload.email})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response.status == 422){
      if(error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}

},
LIST_CATEGORIES: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.get('/therapists/list_categories/page/' + payload.current_page + '/perPage/' + payload.per_page + "/searchKey/" + payload.search_key)
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_CATEGORIES_LIST", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
LIST_ALL_CATEGORIES: async (context) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.get('/therapists/list_all_categories/')
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_CATEGORIES_LIST", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
ADD_CATEGORY: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/therapists/add_category', {category: payload.category, scores: payload.scores})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
LIST_THERAPISTS: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.get('/therapists/list_therapists/page/' + payload.current_page + '/perPage/' + payload.per_page + "/searchKey/" + payload.search_key)
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_THERAPISTS_LIST", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
ADD_THERAPIST: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/therapists/add_therapist', {categories: payload.categories,title: payload.title, first_name: payload.first_name, 
      last_name: payload.last_name, email: payload.email,
      phone: payload.phone, street: payload.street, postal_code: payload.postal_code, city: payload.city, memo: payload.memo, region: payload.region})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
EDIT_THERAPIST: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/therapists/edit_therapist', {categories: payload.categories,title: payload.title, first_name: payload.first_name, 
      last_name: payload.last_name, email: payload.email,
      phone: payload.phone, street: payload.street, postal_code: payload.postal_code, city: payload.city, memo: payload.memo, id: payload.id, region: payload.region})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
DELETE_THERAPIST: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/therapists/delete_therapist', {therapist_id: payload.therapist_id })
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
CREATE_THERAPIST: async (context) => {
  let data = initialTherapist()
  context.commit('SET_THERAPIST', data)
},
LOAD_CATEGORY: async (context, payload) => {
  let response = await api.get('therapists/categories/' + payload.category_id)
  context.commit('SET_CATEGORY', response.data.category)
},
EDIT_CATEGORY: async (context, payload) => {
  context.commit("SET_ERRORS",[])
    try{
    let response = await api.post('/therapists/edit_category/' + payload.id, {id: payload.id, name: payload.name, scores: payload.scores})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
LIST_ALL_SCORES: async (context) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.get('/therapists/list_all_scores/')
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_SCORES_LIST", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
SET_PASSWORD_FOR_USER: async (context, payload) => {
  api.post('/users/set_password_for_user/',  {user: payload.user, password1: payload.password1, password2: payload.password2})
  .then(response => {
    if(response.status == 200){
      if(response.data.user.error){
        context.commit("SET_ERRORS",[response.data.user.error])
      }else{
        try{
          context.commit("SET_CHANGE_PASSWORD_SUCCESS", 1)
          context.commit("SET_ERRORS",[])
          context.commit("SET_CHANGE_PASSWORD_FEEDBACK","")
        }catch(e){
          e;
        }
      }
    }
  })
  .catch(error => {
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.user){
        if(error.response.data.user.error){
        context.commit("SET_ERRORS",[error.response.data.user.error])
        }
        context.commit("SET_CHANGE_PASSWORD_FEEDBACK", error.response.data)
        context.commit("SET_CHANGE_PASSWORD_SUCCESS", 0)
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
  })
},
SEND_MEASUREMENT_REMINDERS: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/users/send_measurement_reminders', {user_name: payload.user_name, password: payload.password})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
SEND_PHYSICIAN_NOTIFICATIONS: async (context, payload) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.post('/users/send_physician_notifications', {user_name: payload.user_name, password: payload.password})
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
},
LIST_REGIONS: async (context) => {
  context.commit("SET_ERRORS",[])
  try{
    let response = await api.get('/therapists/list_regions/')
    if(response.status == 200){
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
      context.commit("SET_REGIONS", response.data)
      context.commit("SET_ERRORS",[])
      }
    }
  }catch(error){
    if(error.response && error.response.status == 422){
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }else{
      if(error.response && error.response.data && error.response.data.error){
        context.commit("SET_ERRORS",[error.response.data.error])
      }
    }
}
}

}

export default actions