<template>
    <div>
        <div class="card col bigcard">
            <div class="card-body">
                <h5 class="card-title">Therapeuten</h5>
                <h3 v-for="region in REGIONS" :key="region">Studienregion: {{region}}</h3>
                <hr/>
                <input 
                    type="text"
                    class="form-control"
                    placeholder="Suchbegriff"
                    aria-label="Suchbegriff"
                    aria-describedby="search"
                    v-model="search_key"
                    @keyup.enter="search(1)"
                    ref="search_field"
                >
                <button 
                    class="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    @click="search(1)"
                >
                    <i class="bi-button bi-search"></i>
                </button>  
                <br>
               <table class="table">
                <tr>
                    <td class="align-top">
            <div class="list-group">
                <button
                :class="this.activeCategory == '' ? 'list-group-item list-group-item-primary list-group-item-action' : 'list-group-item list-group-item-primary list-group-item-action'"
                :aria-current="this.activeCategory == ''"
                value=""
                @click="changeCategory('')">
                Alle Therapeuten
            </button>
            <button v-for="category in CATEGORIES_ALL.categories" :key="category.id"
            :class="this.activeCategory == category.name ? 'list-group-item list-group-item-primary list-group-item-action' : 'list-group-item list-group-item-primary list-group-item-action'"
                :aria-current="this.activeCategory == ''"
                :value="category.name"
                @click="changeCategory(category.name)">
                {{category.name}}
        </button>
    </div>
                    </td>
                    <td class="align-top">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Kategorie</th>
                            <th scope="col">Titel</th>
                            <th scope="col">Vorname</th>
                            <th scope="col">Nachname</th>
                            <th scope="col">Email</th>
                            <th scope="col">Telefon</th>
                            <th scope="col">Strasse</th>
                            <th scope="col">PLZ</th>
                            <th scope="col">Stadt</th>
                            <th scope="col">memo</th>
                        </tr>
                    </thead>    
                    <tbody> 
                        <tr v-for="therapist in THERAPISTS.therapists" :key="therapist.id">
                            <td>{{therapist.category_name}}</td>
                            <td>{{therapist.title}}</td>
                            <td>{{therapist.first_name}}</td>
                            <td>{{therapist.last_name}}</td>
                            <td>{{therapist.email}}</td>
                            <td>{{therapist.phone}}</td>
                            <td>{{therapist.street}}</td>
                            <td>{{therapist.postal_code}}</td>
                            <td>{{therapist.city}}</td>
                            <td>{{therapist.memo}}</td>     </tr>
                    </tbody>
                    <caption v-if="THERAPISTS.pagination">{{THERAPISTS.pagination.count}} Therapeuten gefunden. </caption>
                </table>
                <button class="btn btn-primary" @click="this.downloadWithAxios()">Therapeutenliste drucken</button>
                </td>
                </tr>
            </table>
                <div class="row">
                    <nav v-if="THERAPISTS.pagination" aria-label="Seitennavigation">
                        <ul class="pagination justify-content-center">
                            <li :class="THERAPISTS.pagination.prevPage ? 'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Zurück" @click="search(1)">
                                    <i class="bi-chevron-double-left"></i>
                                </a>
                            </li>
                            <li :class="THERAPISTS.pagination.prevPage ?  'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Zurück" @click="search(THERAPISTS.pagination.page-1)">
                                    <i class="bi-chevron-compact-left"></i>
                                </a>
                            </li>
                            <li :class="i === THERAPISTS.pagination.requestedPage ? 'page-item active' : 'page-item'" v-for="i in THERAPISTS.pagination.pageCount" :key="i">
                                <a class="page-link" @click="search(i)">{{i}}</a>
                            </li>
                            <li :class="THERAPISTS.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Nächste" @click="search(THERAPISTS.pagination.page + 1)">
                                    <i class="bi-chevron-compact-right"></i>
                                </a>
                            </li>
                            <li :class="THERAPISTS.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Nächste" @click="search(THERAPISTS.pagination.pageCount)">
                                    <i class="bi-chevron-double-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>  
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { mapGetters, useStore } from 'vuex'
import axios from '@/core/api'

export default defineComponent({
    setup() {
           const store = useStore()
        const current_page = ref(1);
        const search_key = ref('');
        const per_page = ref(15);
          const search_field = ref(null);
          const activeCategory = ref('');
          const filter = computed(() => activeCategory.value != '' ? activeCategory.value : search_key.value)

        const load = () => {
            store.dispatch("User/LIST_THERAPISTS", {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value
            })
        }

              const search = (cp = 1) => {
            current_page.value = cp
            store.dispatch('User/LIST_THERAPISTS', {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value
            })
            if(search_field.value !== null){
                activeCategory.value = ''
                search_field.value.focus()
            }
        }

        const changeCategory = (event) => {
            activeCategory.value = event;
            search_key.value = "";
            store.dispatch('User/LIST_THERAPISTS', {
                search_key: event,
                per_page: per_page.value,
                current_page: current_page.value
            })
        }

        const forceDownload = (response, fileName) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
        }
           const downloadWithAxios = () => {
            var fileName = "Therapeuten.pdf"
               axios({
        method: 'post',
        url: './therapists/list_therapists_pdf_region/',
        data: {filter: filter.value},
        responseType: 'arraybuffer',
      }).then(response => {
                forceDownload(response, fileName)
            })
        }

            onMounted(() => {
                store.dispatch("Admin/LIST_ALL_CATEGORIES")
                 store.dispatch("User/LIST_THERAPISTS",{
                search_key: '',
                per_page: 15,
                current_page: 1
            })
            })

        return {
          load, current_page, search_key, per_page, search_field, search, changeCategory, activeCategory, downloadWithAxios
        }
    },
    computed: {
          ...mapGetters({THERAPISTS: "User/THERAPISTS", CATEGORIES: "User/CATEGORIES", CATEGORIES_ALL: "Admin/CATEGORIES", REGIONS: "User/REGIONS"}),
    }
})
</script>
<style>
.list-group-item{
    background-color: #1c6e8c;
    color: white;
}
.btn{
    color: white;
}
</style>