<template>
    <div>
        <div class="card col bigcard">
            <div class="card-body">
                <h5 class="card-title">Therapeuten</h5>
                <hr/>
                <input 
                    type="text"
                    class="form-control"
                    placeholder="Suchbegriff"
                    aria-label="Suchbegriff"
                    aria-describedby="search"
                    v-model="search_key"
                    @keyup.enter="search(1)"
                    ref="search_field"
                >
                <button 
                    class="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    @click="search(1)"
                >
                    <i class="bi-button bi-search"></i>
                </button>  
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Kategorie</th>
                            <th scope="col">Titel</th>
                            <th scope="col">Vorname</th>
                            <th scope="col">Nachname</th>
                            <th scope="col">Email</th>
                            <th scope="col">Telefon</th>
                            <th scope="col">Strasse</th>
                            <th scope="col">PLZ</th>
                            <th scope="col">Stadt</th>
                            <th scope="col">memo</th>
                            <th scope="col">Studienregion</th>
                            <th scope="col">Bearbeiten</th>
                        </tr>
                    </thead>    
                    <tbody> 
                        <tr v-for="therapist in THERAPISTS.therapists" :key="therapist.id">
                            <td>{{therapist.category_name}}</td>
                            <td>{{therapist.title}}</td>
                            <td>{{therapist.first_name}}</td>
                            <td>{{therapist.last_name}}</td>
                            <td>{{therapist.email}}</td>
                            <td>{{therapist.phone}}</td>
                            <td>{{therapist.street}}</td>
                            <td>{{therapist.postal_code}}</td>
                            <td>{{therapist.city}}</td>
                            <td>{{therapist.memo}}</td>
                            <td>{{region(therapist.fk_region)}}</td>
                            <td><button  type="button" class="btn btn-primary" @click="editTherapist(therapist.id)">Bearbeiten</button></td>
                        </tr>
                    </tbody>
                    <caption v-if="THERAPISTS.pagination">{{THERAPISTS.pagination.count}} Therapeuten gefunden. </caption>
                </table>
                <div class="row">
                    <nav v-if="THERAPISTS.pagination" aria-label="Seitennavigation">
                        <ul class="pagination justify-content-center">
                            <li :class="THERAPISTS.pagination.prevPage ? 'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Zurück" @click="search(1)">
                                    <i class="bi-chevron-double-left"></i>
                                </a>
                            </li>
                            <li :class="THERAPISTS.pagination.prevPage ?  'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Zurück" @click="search(THERAPISTS.pagination.page-1)">
                                    <i class="bi-chevron-compact-left"></i>
                                </a>
                            </li>
                            <li :class="i === THERAPISTS.pagination.requestedPage ? 'page-item active' : 'page-item'" v-for="i in THERAPISTS.pagination.pageCount" :key="i">
                                <a class="page-link" @click="search(i)">{{i}}</a>
                            </li>
                            <li :class="THERAPISTS.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Nächste" @click="search(THERAPISTS.pagination.page + 1)">
                                    <i class="bi-chevron-compact-right"></i>
                                </a>
                            </li>
                            <li :class="THERAPISTS.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                <a class="page-link" href="#" aria-label="Nächste" @click="search(THERAPISTS.pagination.pageCount)">
                                    <i class="bi-chevron-double-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>  
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
    setup() {
           const store = useStore()
        const current_page = ref(1);
        const search_key = ref('');
        const per_page = ref(15);
          const search_field = ref(null);
          const route = useRoute()
          const router = useRouter()
          const regions = computed(() => store.getters["Admin/REGIONS"])

        const load = () => {
            store.dispatch("Admin/LIST_THERAPISTS", {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value
            })
        }


              const search = (cp = 1) => {
            current_page.value = cp
            store.dispatch('Admin/LIST_THERAPISTS', {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value
            })
            if(search_field.value !== null){
                search_field.value.focus()
            }
        }

        const editTherapist= (id) => {
            router.push("/admins/edit_therapists/" + id)
        }

        const region = (id) => {
            var r = regions.value.regions.filter(x => x.id == id)
            return r.length > 0 ? r[0].name : null
        } 

            onMounted(() => {
                store.dispatch("Admin/LIST_REGIONS")
                 store.dispatch("Admin/LIST_THERAPISTS",{
                search_key: route.params.search || '',
                per_page: 15,
                current_page: 1
            })
            })

        return {
          load, current_page, search_key, per_page, search_field, search,editTherapist,region
        }
    },
    computed: {
          ...mapGetters({THERAPISTS: "Admin/THERAPISTS", REGIONS: "Admin/REGIONS"}),
    },
})
</script>