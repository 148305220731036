const getters = {
  ERRORS: state => {
    return state.admin.errors
  },
  EDIT_ERROR: state => {
    return state.admin.edit_error
  },
  ERRORS2: state => {
    return state.admin.errors2
  },
  PASSWORD1_FEEDBACK: state => {
    return state.admin.password1_feedback
  },
  PASSWORD2_FEEDBACK: state => {
    return state.admin.password2_feedback
  },
  FIRST_NAME_FEEDBACK: state => {
    return state.admin.first_name_feedback
  },
  LAST_NAME_FEEDBACK: state => {
    return state.admin.last_name_feedback
  },
  USER_NAME_FEEDBACK: state => {
    return state.admin.user_name_feedback
  },
  STUDY_NUMBER_FEEDBACK: state => {
    return state.admin.study_number_feedback
  },
  DROPPED_OUT_AT_FEEDBACK: state => {
    return state.admin.dropped_out_at_feedback
  },
  TITLE_FEEDBACK: state => {
    return state.admin.title_feedback
  },
  EMAIL_FEEDBACK: state => {
    return state.admin.email_feedback
  },
  MEASUREMENT0_FEEDBACK: state => {
    return state.admin.measurement0_feedback
  },
  MEASUREMENT1_FEEDBACK: state => {
    return state.admin.measurement1_feedback
  },
  MEASUREMENT2_FEEDBACK: state => {
    return state.admin.measurement2_feedback
  },
  MEASUREMENT3_FEEDBACK: state => {
    return state.admin.measurement3_feedback
  },
  MEASUREMENT4_FEEDBACK: state => {
    return state.admin.measurement4_feedback
  },
  MEASUREMENT5_FEEDBACK: state => {
    return state.admin.measurement5_feedback
  },
  MEASUREMENT6_FEEDBACK: state => {
    return state.admin.measurement6_feedback
  },
  THERAPY_ARM_FEEDBACK: state => {
    return state.admin.therapy_arm_feedback
  },
  SHOW_SUCCESS: state => {
    return state.admin.show_success
  },
  NEW_USER_SUCCESS: state => {
    return state.admin.new_user_success
  },
  USERS_LIST: state => {
    return state.admin.users_list
  },
  PATIENTS_LIST: state => {
    return state.admin.patients_list
  },
  CLINICS_LIST: state => {
    return state.admin.clinics_list
  },
  MEASUREMENT_COUNT: state => {
    return state.admin.measurement_count
  },
  FK_CLINIC: state => {
    return state.admin.fk_clinic
  },
  REGISTER_KEY: state => {
    return state.admin.register_key
  },
  CATEGORIES: state => {
    return state.admin.categories
  },
  THERAPISTS: state => {
    return state.admin.therapists
  },
  CATEGORY: (state) => {
    return state.admin.category
  },
  SCORES: (state) => {
    return state.admin.scores
  },
  REGIONS: state => {
    return state.admin.regions
  },
  REGION: state => {
    return state.admin.region
  },
  SEX: state => {
    return state.admin.sex
  }
}

export default getters