<template>
    <div class="modal fade" 
        ref="listPatientsForClinicModal" 
        tabIndex="-1" 
        aria-labelledby="listPatientsForClinicModalLabel"
        :class="{show: show, 'd-block': show}"
        aria-hidden="true"> 
        <div class="modal-dialog modal-fullwidth">
            <div class="modal-content">
                <success
      title="Änderungen erfolgreich gespeichert"
      v-if="SHOW_SUCCESS == true"
    />
                <div class="modal-header">
                    <h5 class="modal-title col" id="listPatientsForClinicModalLabel">Username: {{user_name}} clinic_id: {{clinic_id}}</h5>
                    <button type="button" class="btn-close col-2" @click="close()" aria-label="Schließen"></button>
                </div>
                <div class="modal-body">
                    <input 
                        type="text"
                        class="form-control"
                        placeholder="Suchbegriff"
                        aria-label="Suchbegriff"
                        aria-describedby="search"
                        v-model="search_key"
                        @keyup.enter="search(1)"
                        ref="search_field"
                    >
                    <button 
                        class="btn btn-primary"
                        type="button"
                        id="button-addon2"
                        @click="search(1)"
                    >
                        <i class="bi-button bi-search"></i>
                    </button>  
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Nachname</th>
                                    <th scope="col">Vorname</th>
                                    <th scope="col">Benutzername</th>
                                    <th scope="col">Benutzerstatus</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>    
                            <tbody> 
                                <tr v-for="user in PATIENTS_LIST.users" :key="user.id" :class="user.dropped_out_at != null ? 'table-danger' : 'table'">
                                    <td>{{user.last_name}}</td>
                                    <td>{{user.first_name}}</td>
                                    <td>{{user.user_name}}</td>
                                    <td>{{JSON.parse(user.user_type).str}}</td>
                                    <td>
                                        <div class="form-check">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                id="checkIsVisible" 
                                                :checked="referencesClinic(user.fk_clinic)" 
                                                @change="setIsVisible(this.clinic, user)" 
                                                :ref="isVisibleRef(user)"
                                            >
                                            <label class="form-check label" for="checkIsVisible">
                                                Patient sichtbar?
                                                <div v-if="user.fk_clinic">
                                                    fk_clinic gesetzt auf {{user.clinic_name}}
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <caption v-if="PATIENTS_LIST.pagination">{{PATIENTS_LIST.pagination.count}} Patienten gefunden. </caption>
                        </table>
                    </div>
                    <div class="row">
                        <nav v-if="PATIENTS_LIST.pagination" aria-label="Seitennavigation">
                            <ul class="pagination justify-content-center">
                                <li :class="PATIENTS_LIST.pagination.prevPage ? 'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Zurück" @click="search(1)">
                                        <i class="bi-chevron-double-left"></i>
                                    </a>
                                </li>
                                <li :class="PATIENTS_LIST.pagination.prevPage ?  'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Zurück" @click="search(PATIENTS_LIST.pagination.page-1)">
                                        <i class="bi-chevron-compact-left"></i>
                                    </a>
                                </li>
                                <li :class="i === PATIENTS_LIST.pagination.requestedPage ? 'page-item active' : 'page-item'" v-for="i in PATIENTS_LIST.pagination.pageCount" :key="i">
                                    <a class="page-link" @click="search(i)">{{i}}</a>
                                </li>
                                <li :class="PATIENTS_LIST.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Nächste" @click="search(PATIENTS_LIST.pagination.page + 1)">
                                        <i class="bi-chevron-compact-right"></i>
                                    </a>
                                </li>
                                <li :class="PATIENTS_LIST.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Nächste" @click="search(PATIENTS_LIST.pagination.pageCount)">
                                        <i class="bi-chevron-double-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="modal-footer"><button class="btn btn-secondary" @click="close()">Schließen</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { mapGetters, useStore } from 'vuex'
import Success from "@/core/components/Success";

export default defineComponent({
    setup(props, context) {
        const store = useStore();
        const search_field = ref(null);
        const user_type = ref(0);
        const clinic = computed(() => props.clinic);
        const user_name = computed(() => clinic.value ? clinic.value.user_name : null)
        const clinic_id = computed(() => clinic.value ? clinic.value.id : null)
        const current_page = ref(1)
        const search_key = ref('')
        const per_page = ref(15)

        onMounted(() => {
            store.dispatch("Admin/LIST_ALL_PATIENTS_FOR_CLINIC",{
                search_key: '',
                per_page: 15,
                current_page: 1,
                fk_clinic: clinic.value ? clinic.value.id : null
            })

            search_field.value.focus()
        });
        
        const userTypeChanged = (event) => {
            user_type.value = parseInt(event.target.id)
        }

        const referencesClinic = (id) => {
            return id == clinic.value.id
        }

        const isVisibleRef = (user) => {
            return "isVisible_" + user.id 
        }

        const search = (cp = 1) => {
            current_page.value = cp
            store.dispatch('Admin/LIST_ALL_PATIENTS_FOR_CLINIC', {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value,
                fk_clinic: clinic.value ? clinic.value.id : null
            })  
            if(search_field.value !== null){
                search_field.value.focus()
            }
        }

        const close = () => {
            context.emit("modalClosed")
        }

        return {
            search_field, user_name,  user_type, clinic_id, userTypeChanged, referencesClinic, isVisibleRef,
            current_page, search_key, per_page, search, close
        }
    },
    props: {
        show: {type: Boolean, default: false},
        clinic: {type: Object, default: null},
    },
    
    components: {
      Success,
    },

    computed:{
                  ...mapGetters({ ERRORS: 'Admin/ERRORS',
                    ERRORS2: 'Admin/ERRORS2', 
                    PATIENTS_LIST: 'Admin/PATIENTS_LIST',
                    SHOW_SUCCESS: 'Admin/SHOW_SUCCESS',
            }),
    },

    methods: {
        setIsVisible(clinic, user){
            let checked = this.$refs['isVisible_' + user.id][0]['checked']
            if(checked){
                this.$store.dispatch("Admin/SET_FK_CLINIC", {clinic: clinic, user: user})
            }else{
                this.$store.dispatch("Admin/SET_FK_CLINIC", {clinic: null, user: user})
            }
            this.search(this.current_page.value)
        },
    }
})
</script>