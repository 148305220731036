<template>
    <div class="modal fade" 
        ref="deleteTherapistModal" 
        tabIndex="-1" 
        aria-labelledby="deleteTherapistModalLabel"
        :class="{show: show, 'd-block': show}"
        aria-hidden="true"> 
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title col" id="editUserModalLabel">Therapeut wirklich löschen?</h5>
                    <button type="button" class="btn-close col-2" @click="close()" aria-label="Schließen"></button>
                </div>

                    <div class="modal-footer"><button class="btn btn-secondary" @click="close()">Schließen</button>
                        <button type="button" class="btn btn-danger" @click="save()">Löschen</button>
                    </div>
    </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    methods: {
        save(){
            this.$store.dispatch("Admin/DELETE_THERAPIST", { therapist_id: this.therapist_id })
            this.$emit("deletedTherapist")
        },
        close(){
            this.$emit("modalClosed")
        },
    },
    computed: {
          
    },
    props: {
        show: {type: Boolean, default: false},
        therapist_id: {type: Number, default: null},
    },
})
</script>