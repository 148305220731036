<template>
    <desktop v-if="!isMobile"/>
    <keep-alive>
    <mobile v-if="isMobile"/>
    </keep-alive>
</template>
<script>
import { defineComponent } from 'vue'
import desktop from './Measurement-desktop'
import mobile from './Measurement-mobile'
import { mapGetters } from "vuex";
export default defineComponent({
    components:{
        desktop,
        mobile
    },
    data() {
        return {
            isMobile: false,
            mql: null
        }
    },
    mounted() {
        this.mql = window.matchMedia('(max-width: 700px)');
        this.isMobile = this.mql.matches
        this.mql.addEventListener('change', this.handleMqlChange);
        this.loadMeasurement();
    },
    unmounted(){
        this.mql.removeEventListener('change', this.handleMqlChange)
    },
    methods: {
        handleMqlChange(e){
            this.isMobile = e.matches
        },
        async loadMeasurement() {
        if (this.$route.params.measurementId === "new") {
        this.$store.dispatch("Measurements/CREATE", { fk_user: this.FK_USER });
        return;
      }
      if (this.$route.params.measurementId !== "new") {
        await this.$store.dispatch("Measurements/LOAD", {
          measurement_id: this.$route.params.measurementId,
        });
      }
    },
    },
    computed: {
    ...mapGetters({
      FK_USER: "Measurements/FK_USER"
    })
    },
    watch: {
    $route: "loadMeasurement"
    }
})
</script>
