<template>
<div>
    <div class="card col bigcard">
        <div class="card-body">
            <h5 class="card-title">Kategorien</h5>
            <hr/>
            <input 
                type="text"
                class="form-control"
                placeholder="Suchbegriff"
                aria-label="Suchbegriff"
                aria-describedby="search"
                v-model="search_key"
                @keyup.enter="search(1)"
                ref="search_field"
            >
            <button 
                class="btn btn-primary"
                type="button"
                id="button-addon2"
                @click="search(1)"
            >
                <i class="bi-button bi-search"></i>
            </button>  
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Bearbeiten</th>
                    </tr>
                </thead>    
                <tbody> 
                    <tr v-for="category in CATEGORIES.categories" :key="category.id">
                        <td><a :href="'./therapists/' + category.name">{{category.name}}</a></td>
                        <td><button  type="button" class="btn btn-primary" @click="editCategory(category.id)">Bearbeiten</button></td>
                    </tr>
                </tbody>
                <caption v-if="CATEGORIES.pagination">{{CATEGORIES.pagination.count}} Kategorien gefunden. </caption>
            </table>
            <div class="row">
                <nav v-if="CATEGORIES.pagination" aria-label="Seitennavigation">
                    <ul class="pagination justify-content-center">
                        <li :class="CATEGORIES.pagination.prevPage ? 'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Zurück" @click="search(1)">
                                <i class="bi-chevron-double-left"></i>
                            </a>
                        </li>
                        <li :class="CATEGORIES.pagination.prevPage ?  'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Zurück" @click="search(CATEGORIES.pagination.page-1)">
                                <i class="bi-chevron-compact-left"></i>
                            </a>
                        </li>
                        <li :class="i === CATEGORIES.pagination.requestedPage ? 'page-item active' : 'page-item'" v-for="i in CATEGORIES.pagination.pageCount" :key="i">
                            <a class="page-link" @click="search(i)">{{i}}</a>
                        </li>
                        <li :class="CATEGORIES.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Nächste" @click="search(CATEGORIES.pagination.page + 1)">
                                <i class="bi-chevron-compact-right"></i>
                            </a>
                        </li>
                        <li :class="CATEGORIES.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Nächste" @click="search(CATEGORIES.pagination.pageCount)">
                                <i class="bi-chevron-double-right"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        <input type="text" placeholder="Kategorie" aria-label="Kategorie" v-model="category"/>
        <button type="button" class="btn btn-primary" @click="addCategory">Neue Kategorie</button>
        </div>
    </div>
</div>  
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup() {
        const store = useStore()
        const current_page = ref(1);
        const search_key = ref('');
        const per_page = ref(15);
        const category = ref("");
           const search_field = ref(null);
           const router = useRouter()

        const load = () => {
            store.dispatch("Admin/LIST_CATEGORIES", {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value
            })
        }

          const editCategory= (id) => {
            router.push("/admins/edit_category/" + id)
        } 

            const search = (cp = 1) => {
            current_page.value = cp
            store.dispatch('Admin/LIST_CATEGORIES', {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value
            })
            if(search_field.value !== null){
                search_field.value.focus()
            }
        }

        const addCategory = () => {
                  store.dispatch("Admin/ADD_CATEGORY", {category: {active: 1, name: category.value, scores: []}})
                  load();
        }


        onMounted(() => {
                 store.dispatch("Admin/LIST_CATEGORIES",{
                search_key: '',
                per_page: 15,
                current_page: 1
            })
        })

        return {
          load, current_page, search_key, per_page,category,addCategory, search, search_field,editCategory
        }
    },
    computed: {
          ...mapGetters({CATEGORIES: "Admin/CATEGORIES"}),
    }
})
</script>